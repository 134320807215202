import React from 'react';

function GyroscopeIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFFBohKfzl5AAAH0tJREFUeNqFm3ePJGd+3z9P
qKqO0xN3ZifsciO5ieSSPPKOl3jHE09nWYagZMtKxkGGZFmAX4Jp+C3IsAFDhuWDDhLOlmTL8sGS
rEBe0pFL7jIuyU0Td1J3z3Ss9DyP/6junu6ZWaqA3amqrvT95fSI6VNnHIAg21KXUimO89TYiwQi
xx37Dh328fABd3Cl6B0e2fonxeCMOLKTHQhGH5ftiIPHO3fMo11v14ED1zvnXHbgcFjrkFJmz3Ju
8Jz+PeCw1tJsNtFCHDzcOIvOKb428/NcCp7DI6CV7rHmblOgcAjAwX3H0qEPSoweuz70w8ARiEc8
/9DOADR9IjnXw5UBE1Kg/WAUvHM9QmXvTJKY/f0G+jD4z0x/mcAL+CD9e3wRUHOb4ATukTAPE2FI
Sg4Rw/XOO5HdIBD0xU/0Pu4RQnREAg5AZWecczhrQQi0F+DsgUQMCDT0DtcTNg1gsHg5zfMzL3Eu
d4XUGfKihE+AQtP72kdvR9TB9VEd4prNLh8AFCBEXxk+jX6HRKpHQMEQ0RxCSjw/wDkLfZaNMOeo
3mpjDV7OGwKfDr7keEYMH32KVBiDEwKpJNY5lKdRQQ6sxSmNsBasxUTRAYGcQ8hj0A/R040AGSKG
lHi+P2I3UpPiHEilEM4e+nYBCLSfD3hu6ouHwGcXHAV+8PoR+ENcwBocAm9qikQKXL5A3GqiWi1U
p0VsHdI5pDEkcYKvFYVymShO8IKANIp7wjGCePDOI0QYcN7HOZupA2CMZXJiim/++q+yvbvD7/3X
/0bO93oSc4BMnx47y/n81UPgDwEUB69/pJ2yFgTIEycIFhcI212iRpPo4Sa22UCFXYSQGEArie95
zE1PU8p5VKtVclJBmuAXc5jUEMVpBq737iOaIcDZHvigp/N9svTE/uLjF9hrNigVisdKqxCglZBH
3c3xjD+eOM7hjEUWi4hKmShN2Ltxg8bWJkkYYUxMmsQ45/AAJwQgmZyaYunEFLdv32Zyapqp8RLd
VoeoG1Ip5Il9j1aUkMRx5tIOfYZzmavri/3AzPUopaTkb197nYc7Vb7x1S9zvGER6GEgx/qhQ8Zt
5LDHAT03Q6OxR+PWW0T1+oAbUZoSGYez2X0hDmEtwlnSKEakCSBYXllha3eX6ckJCsUSkUnJa49S
uchWqEk73UOS6JBC4AfBgHmZR3Ej5sHXmsBTx3D0YNOjYN0Rv33c+T54BIjJCTbvfIRIEpL9fTrd
kCQxxMbirEHi8BSUCjmCQgmlNAhJamE/NJg4RDjDXrPB7s4mC/OLXDhzFukHhFGIThJ0pUJ3r4pA
Z6LvHNrzBiw5cIZHmeY+TXoB3XcTAx0bUrZRfh840Cz4MJh8ke133mZqdo7F+Tk2Hizz4N4DBF3K
eUcpp5ipKMpFTeBr8oHBV47Ac9SiApE3RRLFPHgYUa/WCcOQew+W6XQ6XLt2ldCk5AIfzyW4yjjh
Xh2EwvMDlFK97xhi+TFoxbHwh4zg4RAGhv30wflByGkdAkvkLNX332Pq8UsszE0gOhHCWk5UBFMV
DyU9LD44S2Jikk5MqyvRwuAryPtdlnI1xqdzPDtfYq1e4aMVyfLDLptb24Thmzxx6QlyEyVK5TFO
FkpsjE1SXVtBKd3z9Qx7wuM3IXqG9CgzHaAWT55/dcE/mz3wkA1QQrNm7xK5DjozYUilSJVif3ub
k09fJ52ZodhtkXSatB6+Q9mPcFaSGh/pQoo6oiAj+uGO6HEqNIpqU1Ntx0g6PPVYnmsLMFkI2d6D
WqNLtV7n8cuXWTp1ikI+zxNnlthvt2k0mgPDODD5w4owZBc63ZDVtTVazdbAowBYa2m1WkgXuQM9
6sXVDOLmgzjaAc4YEpMQxjGLL3yObe2xvraGQWLCPXwShPBQWAqqQcWPCIjA2EysBHjK4iuHFpaC
nyKsY3lT8Le3asRS86Xr8/ziC4KliZR2q833v/8albExrly9wpVrV3n+2WdQns6+aVhSR8LoXkIl
BN12i5WVFZSUHCcnshHWSWyM7MXlo8FNL4524KwlNZbUwswTl9jfXKe8X2NsZzuLcE2aBSLOIqXB
UxZnDHEqSNBYI5DOolWKlgkF31DwUvK+JeclJJHh796sUg0VL77wJN94UrM4Ydje2uZP/viPmZ+b
JV8ocebcBRZOnyJNh+KWvnk6xmRJKdHqwBO4Q9eq2E9fdc4xW1w60P2eIZBo1u09ItdCCw8rBKUz
59hZXUbs7zHh5wmbDXKlCiZskjZ30B54KgXjwAqkp4mtRgpBoC15z6Gw4Cw53+ErgydclhZIwb3l
fc5fmOTKE2exrTWWdxUPt3Yp5gs8ff0ZpBDE3Zjl1QcYY3pqeziLOvANwxwdTkGsNbRaLVRQqry6
1VnF8zxmc4u4XsKS2QDFur1H6DqQWLq+T6vTplurEltDoH20EAgh0Z4krq7hjEEqcMZipGA/ljTa
jq4xOJmFqjlPEGiQOCSOQDnyvkVqQSfWfHKnzvXPXWc6D42dTVbqmjt3PuHylcsoz6e+V2e/2aDR
aCCFHEmPOeQUjzjJHr36NkAFxfKr1llC2eZi+VrvhkyHpFBs2LuEtkW0v0fLZgnGqbk5vvHSV/j6
V17ia1/9Ck9fvcwLn3mBK1c/y7nHr9LYj9jYj1ipd+ga8HBIz9K1jrWaY33XkaSCgi/wfQFSoGRm
JiKrMdayurrNT/3sT2Bqm6xs1tncjdnZ3iRfHufe/fvUqnUik2ZSe8jIj2j6sSn1AQGG3KA4lCgc
hJbOGWq720yfneRrX32Zn375ZeZnZ9FKIaRESYlSCiEFUkh++p9+k7v37/LH3/pdHrzz15QKKQqB
dWCK0I5hrerY2ocnFhXjxSyOsxY8abBSsbtZ57W/+5AXvvokNz5+yGoVbt/+iPG5RWp7+/j9ENg5
BPKY1PfQiRHjJgY4VVAsv+qcIx8UuDj21MjtSmg27H3qrTV8EfA7v/XbvPKlLyKtYb9eY3d7k3an
A0IihcBaizUGP/BZXFrkhRdfRqg8H916gzhOUVqQ98D3YKokSA2s7lq0FGglsCazD0I6nFBsbe9y
5epjmMYuH610aYQQ+B7eWIWus1hfI8Ko5xIP8v/+P3c0bx1soyqAo5DLc7H85JDcCJRQbNq7oLv8
3M//CtcuP87ag0/4+L2brKwss7b6gLX1DdbXV2l2QkrFAvlcLrtbSArFIo9fe5Zux/DJ+2+RGoMx
oKWgHMBkCaJU8LDuyAcKpbJMsZyzOBytDngiYqKQ587dXbY6HtJZCnMLxMaShDFRq0k+yPfgj7Jb
HAHuPo0ABS6WnzqoFQESST3dYv7cDJ+9/gzV6i5bO3WMUPjlSYwTIDVOKqq1Gpvbu1TGxigWC0gp
EUIipeT81evc/eAj7t3/hCTNQqK8D1JCzocohWbXUQgUFsV0KfvYFM1+rcmZ0zlW1jo8bAiixJAv
FFHGQJy5b8/3jgU5qhejxHg0ATgggE0d02fGePJzlwj3qzRaXTw/wM+VWNu8z4/efp3p8Rma3QaF
XIkkjtje3WFudg5PZ75Xa4WUkvL4DHdu/oBCQdBsWZwzaCXQCooB7DShlBcUfUugoZgTJKkBHDlf
ESeOe5sJsVPkc/ksDbaGNEmyvF57R+qWThwhycAEWGNptf8hAiSW2cszTJz0qNf2UdpHez4P1u/y
F9//cySOpy4/yw9vvkarWWV+ZoE4NWANMzMnMjuiFUmSMjN3kqXpKZbGPmBxMc+91RBnDVYIPA2p
hW4syAWSnAYlBdZl1SVnIPAlt9cdsdNIAeWJSaRUGOcoFfLHBEGPSBDEkAS0W4fS4cNkkg5dVCRx
jEGCTdEu4N7KHXQguHrxDL4KSLG8c+89Tp48y/zsadY3tjl3pslYpUy3k4WkYRhy/es/S+EbF6nf
+y+0ut/jjb/fYaIIiQNJVtezNlMJKR1CSJRyhGFMLshT9i3NjiGJY4JCGYvFy+fxnaFr+iZPZP7U
HSLGEQ3JdvSBsbBHaKALmtkLM1TX3kVYA16OKIkp54o8/6RlqhIS2g5L85Kd7ay6jHOESUy1ViNf
yAECISUbGxuZ2LsCpi1Q7CNSCCNwGqQUOCMQzuCkIDI9UUURdiVjZUlOJ5jsYpRLyZfGyKcRzVZr
KHo5Rg0OS4I4OKWHbebhK5RWnDgxycrtLipXxBlLHIWUSh6F4iJjxS+iBEyMtbh0YYF8UOjV5x3t
dgdrwEiLsLC3t8+PX/+/rN76Dk+citjdt6RCsFU15PMCjUNJh0kcngAtIEygbQW+hcCHnKcy0yyy
eMNTEpLMnggOV34OqcEgMnYjIb88ohzDt0ublbWlh5BZfyCKY6q7DxjLn8dTRXxVpOCdAVfElwFC
ClJriJOYOIpI4gQpJRsbD/nk/gpXnrhAq9qhWC4SOfAF+KmjLMGTDmEhTSxOQGgkcSLRwhElYEyW
UluTYqzDGkitzRoihzX4EZiGfxGjBDh8hQMj2N9vISUIm6BF9vJyySevTuCSEBNHdE0TIUsU8wVM
muIpBc4RJwlRHGOM4emnrvGLv/SrdGoNNmuW1p6lGzpQAj8nkAp8JUgsJFaRJBDFEmfBAI2WoxNn
JTbpLM4kpGlMqjxQvebNEZfnjsQCYuj/gQpw7CWCNEpZubeGdY4otUipWNlYoVIpo3UFRICxHYQt
UVABnh8QJTEIQbFYBOFITUoUxwilefjxLV77/ns0TIpWhm4KhUAgNdSbUB6X7CeWbgxKgRIG4xRI
aHYMnYhepCjx0gjb2oNiGS/Ik3a7Pbf3qSXswydGS2J9CyrIEhQbG/Y32shxD9OoUZmZZyxfoVbv
Iir3WBq7QDP+gO3WPtqvYK0FZ0jSlDCOWVpaQipNGIbkcgF782dYbZXYrNeYzMNYAQIJUkAcQrOR
IrQjtYJOJAhTBVg8TxCGDlQBooTA05mEegWkUyAMDjda0xjqGw6DPtxfkPSLVUL23P/Bz0pqmrsd
lG+Iog5JFHL23AWULLP24Dar9b/hzt277Kxr5mcWMDYz3UoqlldWWF1bp1goUCoWKRWLXP3Mi3z2
lZ8m0DDuO0oC0ghqNXAWwo7BWUlsoBtDKxKEBjytSFNDggSp6HQjojhBuJRAHniwfixw0EI8rlw2
KgMqVxp71WHJ54o8Xn6yd3NmbLTSfLh1g7SU0IkV5VyAEILyWIX1+zvsPuyQ9xd45uqzjFfGM88h
FUoprHGEUcTc7AkKhQLGWqSULM3P88Gb38N09nouSBAnjlYqyAUCIwUIReoU7ViBlEwU4P56QiPW
eH6OXKGIwVEsFhFpQjOKkYd7GscUdgf/9RK3druNypXKryIchVyOi+WnB+ABtNRsJcs82HnA/OkL
7DbaeEpSKuSZnj7BzMw8586cRUpAKKTMpMn2mhRJHCOAxcUFpBBZv256hpzO88EP/w4hLc4K2jaz
BcoXOCkQSpCkgk6i8ZVFGsNa1RHLAlprxiYnyQc5tOeTLxbpxvFIC+1oaY8jAVGfABIBQRCgpBzK
kvs3CQgsO5trvH/jxxgEHz5Y490795E4JsbHsGmCFA7rUrAGpCBLTiVxmnDn3n3W1zfQWqN05h2+
/I2f4tJzn8fiaBhQEqQvMBkliWNHlGZcUi5mfSelmWRJj0lTpHOYbgcXhuw394aqvQdF3QMcRw3f
8CZ9z0dqOJl7DCl6wUSvMyuAVEQUygU+uvkmH/74eyhnWNtrsdNuY+MYKRXOGJy1GLKGpVIKXwuk
lLTDkPc/+IBuN8TTHkIKSmMV/tGv/SayMEXed3ieIHb9rq4jTiBKBD4JUddSbzkS51EuFjh1aomc
p7BC4hVL6GASXMKnbZ/S+UQqX3Cm/ARPVT4/6K0N6veAQRLkC3j5PPc/+ZDbN29g9us4FdBoN+hX
9gb9dymyKo0QSKkQAtY3N7l7/x5SZRVaaw1PfeazfP1nfh1PSpSXoY9TaEaKvUiTGEiSlHrT0jEa
JwTVvSalyiSp0yRKYeU4pxdfybzPkVrQ8FjEo0mgzi5de/X5ia+hhY/DjjTAldCs27sYFaFkQHuv
jnCWndUVojBkbnEJSYrv5/C8ABt1cdaiPD97sRQ4C6kxtFst5k7MUCwWMdYihGDu9GluvfMumxvb
tFNJmEo6iSY1AmkT2m1DIxQ4L4dQWcy2v98iTCIcin/yky/TaVewokir+SFS5g5ADyUBx/UHBzag
6JUIZA6LeUQfDbCO8co4hfGprIgg4eN3b7KxVaUTGxQOa8zIVIzotd2FkCip2Fx9wM0bPyJNE5RU
4GB2boGf+LlvIqceJ449khQCT+KLlEYzpR6CVb0mqHUI5ZMCYRxTLOSRIuXc6Qgt5vD8Am5IFfpz
SEdnm0aP1dL8+Vfng7NHuA8gheShu09Eh0D6WKnYq1axLgPcbXdYOHseZWO0JOsGa4VUQWZGegVV
rT2MTakuf8TU1AzTJ+ayZ1jD6dOn2NjepBPGyDih22my24hpxiC1R6AVSIV1GqTGWoMk8yydTsjZ
x6bY3tbETBJ1PkbIoCcBxxWC+jXvITe4tHD+1cXg7BFKWQwFMcY263RcHQ8fX0s6qSJqN1FK0qrX
KI1PUJmYIBfkkDhUEGBxvYgwwdqseaG1R5RYmnu7nDp1iiBfwDnQWlMpl7n5zi2C6QVip6k12lgn
yPkeUmliJzAWpHNYYxmfmqIYaKLEApIzj81zZ+Uizr4FzvTsUi8WEqOcF72S34Eb7JmJw+ADUWBM
TA7VVR1ae8yfPIGfK2R3KcVH77xNo9Vhf2cdm4SkSYJLE5wzmY+VksQYUuvwxqbYbkR8cOsthLNZ
Jdk5Ll2+wuUr11i5e4epk/Nc/+znOXvuDGhNZCGJYpy1JCYllwso5z18rQDH6sY2vt+g7CUk9jrW
tUeFfeAFxSBXGma1PKz4FkNOFCiLieEG06D3EHiSialJjAWEpFar8cnHn9AOI8JOKxuAkAIhBUpL
lJD4SmGcRQE6X+Ld999je/VO1gwR2RTXL/3zX6ZUKnDjhz8kjLrMP3aOudk50jjC2RSbxggcC4tz
jFfKNJsN9vfrRGGLldUVGrWPmJ3+KoX8ZNYyO8aeHbT/D0iglhbOv7rgn8NhB+BLTGYcR7PsPqTt
9vDwMMZQLJWYm52l4Ad0Oi3SJKFW3WX+7EV8rSiPVVC6J4JCkNqsP+8pnSUsUtGs7ZB0W5w+ew6h
PJy1lMplyqUyN958g4mxIrnKGLlcnkIuR9jpYJ1lfn6W6s4Oq6trPNzcJklixvM5whj29/coV77C
Sy9Ncuu9t/EGKTJHyoMCsM7R6duABf8shpScKFIWk4MbNZoH7kPadg/lNOXKGEkSMjk+xbmzZ8kH
OarVXTqtFkjNifOXCKTE83JZQOwUTihsL0bI+ngGlEetXmO8mGfm5EIWSDnHmTPn6Hb2WVlZpdVs
srW+jkMwf+o0E5MT+EHAg3v3cQ7yhRJRFGWBVSGg3hxD5D7Hv/qNK9y78z5bWzuoQVf4cMVUZFWr
TjvLBi12IPa2NxswnBg6ZymNT0BYp/Hxa3SWX2ezvsulq1f4yksvs7R0itW7d1hbXaPejXAmARQO
ie4NLfc7uVIopJ9D5Ce4efMWrdp2rx6Y4nuKV37yH7O1tcvbb9xg+cEy9+7cYXX5QRZFOksQ5MgX
CkglUUqzvVuj3ajhm4DUJezV9/gXv/Yr+IHfC5AOW7n+kLI7UIFzwVUKYrzXZxvSD6G5bz4kDbr4
uoDoNgmSLVLnQWmB/Xab8xfO8+z161RKJXY2NyjNLhAIR+AHgMM4k6Xu2sviBCEAi6c1rShBYVhY
WMBah00jZubmMcby+uuvYa3FOUcaJ8yemCUKI7rdCCE1SZoiMBib0G6n5Av/DH+6han/d7705Z9E
4rj13vsDKThSF3BZ3VKdX7j26sXgmZ6YukPFAsWK9xEdmiTGI7SSHVHGKyxQKo/heT77jRYIyeUr
lzmztEQu8KinjqISKM/DGoeWCqGyafA4SXC4TEelz269zvzcCcYqEyRhFyEV5y9cZGd7h0ajwezs
HL/xL3+TX/j5X+QLX/gCX/rCi5ycnWJifIJaew7ln8HL/wLp/BeZdt/C1j9B56d5+Se+xps3b1Gv
11FKjoAHsNbR6bRR5xauvXo6eIIslRnqDTuH7wesyI8IXYMCisQ5xkrjdJ2ja8GTEu15pGnM1k4V
JwQTlTG6qkC3tU85l0cI0TN+EixYl9UFdndrpGmC9nziOOX06SWCIEcSdSkUy3zu8y/yxS9+iS9/
4fMsLM1TKBWYOTHNXvUkH79/lXMXn+a557/OXvslrj9+mnOzf8PFhQ6TQYG17SrXn32O8coYP77x
FkrI3rBUv/wjcC6LA7TjaLjoHChfIbWEtE87QUErsAljngTTpVZtUt8P8JSkUB6j1emyur5JvlSg
MDVLt9shCAIMApekA2e0ubHO2q2/JHEBz7z8Cyyvb/BgeYWLFy7g5yFNYsqlEhcfP89f/8+/4s0P
GhQrCZ2u5K1bAl93+GTlLZ577hyXH0uZPTFDqZhD6UuEjTrv37zF73/rD9ja3iZsdUjU4SGKTALI
Jg85Al76EqHEoSSiT6b+TGYWqTmbkiaWZrVLNTV4QQ6/KplXOR66EoueD0Jl1l9YNh+u07r7Ok8v
CJpRzPbmOotLp3nzxlvMzc4yNjaGsRlbWq0OP/jxDV7/0RpWxEivxAsvnOXFFx6j0XyRD2/fQwpY
W1sdhLhCSlKn+Mu/+n90uiFaKUx6/By04MikaAZeqr4p7A1IHSqkHQwaZeUlJbPEJ+dLrEnohglr
dz7g9LVnaLbbjJeKIAS7u1Wad37Ac6cN9zYljXZC7O7A4ml2dnZ499bbfPbFL+D7AQ/u3+fPv/sX
dOnyzPOTJIklny/wcz/zEp1Oh/MXZgCPjz7+mMD3s0Jst8vO7i4Pt7aJezPGqbFodVz1f6g11geT
ib3oT8kdxNGDfXcwhdbbGVaf/lid9jyisEN9Y5Xi4gIlX+Pni6yvPuCkqpHTZZQ0nKhoauE61WqN
ybEC7779BmdOzbPfSfmTP/0z6nt7lMtlUmMxJkUrhdKKRrNBZbwCQJokNBsNtnZ2qNXqpGmaTa1o
zb/5nd9mZXWDb3/7DykUDuaKh7fBkpnD4B9BsJG+4+jx0GInHEp71LbWmZmbZ6/VZsbPceHiFZbv
Sv7i9gMqQchEEKNdyvLKPSaeuECrts3/+M532G5ERFGM72VRohSQLxa5dOkSY2NlLl96mW4YUq1W
+fiTj3njrbez8llvVCeKEz734uc4f+4s7VY4Eg+MbP0lM8qXWOmOgH90Y+noRSPDKK4XBicpO+sr
NCrj1Gu7LJ5c4MqTzxCbZwg7DXa31mjUasyMV4i7LTZ29lh97x5T09OUyxVsmoBUxKnh6tWrvPLK
yzjn6HQ6xEnC0089ied5vPf+hzQajczjOEepWOLF5z/D//nudzk5t/iIVQ49CZBKHBg89wiYPYsx
Kv5Dk+lDUiB6ZWfnMinY2lhlNl/ivU7C3b07TASKk5UyM9NTPHbmIt5FTXuvxu3bt1lZ2yIMu8RR
TLfSplyZQPSmwsvlUna+2x2U3NI0ZW5ujkqlwt7+PhKI44hXvvYyH9+7z937yyzMn36ESGdfqwfA
jlzjRinQq7S6YehDtkD0jl1vFrgfSmvPZ//hKoXyBEmhwqox3N+o4W/tc7KUYyrnU91YZ215mShJ
SKxjr9Wmutdk+kSMkGCsI45jkiSr+PSrwFIp6vV6lhOQlblmTszyzLPP8B/+03/m3NkzBL6H0uqI
/veX7mkepfPH6MvxhDosFf2lcVnaKYTMVozsbqC8Kp5zFCZOkCjF8l6bO9X7RHs1bKeNEAKTGqSU
SO3RaDaJ4oSpqUkmJydHdFJpzcbDh3z7j75Dp9NBSokxhtOnT3NqaZF//Vu/wdzsLCdPnuTGzbf4
3vd+gPZUvy9CmmaBn/40xId7jWLgDQ/couibPSEQzg08R18tRL/XqDTOpGgcrrqOjRJEbJBpiud5
tIwDm5LP58j3Rl6y4ilUxsaYnJzIghchUEqxs7PL7/7H36MyVuKrX/kyf/a/v4vWmg/ef59/++/+
PdY6rj35JC889wxvvPFm1pPoSU+apsRJJlG6z8AjDB1yfaMaccgAjhiGwVLI3tqgrDo7WCgpsmJp
HCeE3Shb9yMEvk2pjJWywYde1NZfPWd7H9zthnieB86xtb3Nt//wjygW8nzz13+Z7//gR73mqMQa
w87OLsYabt68xebGNt1uRBBktiRNU5I0JkpirDHDEyJD2j8i68esFT5mPHX40oG0iAPC9I1C1A3p
tru94eiDAGUw0e1GGaKk4uHmJr//rT/g7NkzOGBleYUPb9/m+tNP87/+/LvcePPGUPouMkJKSb1W
ZWd7ewDepCmJSTLw1iBELxTOChWjwyKObE5QDK/uFK6v3AeMH6jGMPqDNX39XqNzjk67Q9SNsp/F
pzmno4969733uHnrVhat9hosP/zRj3DW4Xn6YAHFMPGURPUkyhhDYlLiOMYaOzCkOjJdtuM1jDOH
9JxsRN7E2diKSEY7rMdsw7+P9Oido9vpksQJQh4CfmgR6KOo4nkazxsdZ/APgRZD0chwcGatIbUJ
cRIPFlcP7ll4/JxLbHSMGGc7vvDR+L3njlqBf5CD/fhh4BbF4O/Bn6GhleNWrPUfNUJYRu7vp7mH
b+oPUzuXzTZlSdHoRf8f8I7LijoTm4QAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQtMDVUMjA6
MjY6MzMrMDA6MDC6hm/TAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTA1VDIwOjI2OjMzKzAw
OjAwy9vXbwAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default GyroscopeIcon;
