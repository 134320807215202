import React from 'react';
import words from 'an-array-of-english-words';
import { Button } from '@mui/material';
import WordList from './WordList';

const wordBank = words.filter((s) => s.length < 5);

const getRandomWord = () =>
  wordBank[Math.floor(Math.random() * wordBank.length)];

class WordGame extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentWords: [],
      gameRunning: false,
    };
  }

  startGame = () => {
    clearInterval(this.timerID);
    this.generateStartingWords();
    this.setState(() => ({
      gameRunning: true,
    }));
    this.timerID = setInterval(() => this.addNewWord(), 1000);
  };

  stopGame = () => {
    clearInterval(this.timerID);
    this.setState(() => ({
      currentWords: [],
      gameRunning: false,
    }));
  };

  generateStartingWords = () => {
    const startingWordCount = 5;
    const startingWords = [];

    for (let index = 0; index < startingWordCount; index++) {
      startingWords.push(getRandomWord());
    }

    this.setState(() => ({
      currentWords: startingWords,
    }));
  };

  addNewWord = () => {
    const { gameRunning, currentWords } = this.state;

    if (gameRunning) {
      let attempts = 3;
      while (attempts > 0) {
        const newWord = getRandomWord();
        if (!currentWords.includes(newWord)) {
          this.setState((prevState) => ({
            currentWords: [...prevState.currentWords, newWord],
          }));
          return;
        }
        attempts--;
      }
    }
  };

  render() {
    const { currentWords } = this.state;
    return (
      <div>
        <div className="button-row">
          <Button onClick={this.startGame}>Start Game</Button>
          <Button onClick={this.addNewWord}>Add Word</Button>
          <Button onClick={this.stopGame}>End Game</Button>
        </div>

        <WordList words={currentWords} />
      </div>
    );
  }
}

export default WordGame;
