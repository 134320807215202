import { Grid } from '@mui/material';
import React from 'react';
import Generators from './Generators';
import Inventory from './Inventory';
import './ProgressStyle.css';

const itemDefs = [
  {
    id: 'g',
    name: 'Gloops',
    description: "It's gloopy",
  },
  {
    id: 'b',
    name: 'Bloops',
    description: 'Bouncy',
  },
];

const genDefs = [
  {
    id: 'b1',
    name: 'Bar1',
    description: 'Basic bar',
    purchaseDescription: 'Buy the basic bar. Gotta start somewhere.',
    valueMultiplier: 1,
    collectionCurrency: 'g',
    baseProgressSpeed: 10,
    baseUpgradeCost: 0,
    upgradeScale: 5,
    upgradeItemType: 'g',
  },
  {
    id: 'b2',
    name: 'Bar2',
    description: 'Better bar',
    purchaseDescription: 'Buy a better bar, it makes better things!',
    valueMultiplier: 4,
    collectionCurrency: 'g',
    baseProgressSpeed: 5,
    baseUpgradeCost: 50,
    upgradeScale: 25,
    upgradeItemType: 'g',
  },
  {
    id: 'b3',
    name: 'Bar3',
    description: 'Advanced bar',
    purchaseDescription: 'Buy an advanced bar. This one is really cool.',
    valueMultiplier: 16,
    collectionCurrency: 'g',
    baseProgressSpeed: 2,
    baseUpgradeCost: 300,
    upgradeScale: 125,
    upgradeItemType: 'b',
  },
  {
    id: 'b4',
    name: 'Bar4',
    description: 'Super bar',
    purchaseDescription: 'Buy the super bar, better than a space bar.',
    valueMultiplier: 64,
    collectionCurrency: 'g',
    baseProgressSpeed: 1,
    baseUpgradeCost: 1000,
    upgradeScale: 725,
    upgradeItemType: 'g',
  },
];

class ProgressBarGame extends React.PureComponent {
  constructor(props) {
    super(props);

    // Initialize game state
    this.state = {
      items: itemDefs.map((item) => ({
        ...item,
        value: 0,
        maxValue: 100,
      })),
      generators: genDefs.map((generator) => ({
        ...generator,
        level: 0,
        progress: 0,
        maxProgress: 100,
        collectionCount: 0,
        collectionMax: 10,
        upgradeCost: this.calculateUpgradeCost(
          generator.baseUpgradeCost,
          generator.upgradeScale,
          0
        ),
      })),
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(() => this.gameTick(), 10);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  gameTick = () => {
    this.tickAllGenerators();
  };

  tickAllGenerators = () => {
    this.setState((prevState) => ({
      generators: prevState.generators.map((generator) => {
        const gainedProgress = this.calculateProgressGain(generator);
        let newProgress = generator.progress + gainedProgress;
        let newCollectionCount = generator.collectionCount;

        while (newProgress >= generator.maxProgress) {
          if (newCollectionCount < generator.collectionMax) {
            newCollectionCount += 1;
            newProgress -= generator.maxProgress;
          } else {
            newProgress = generator.maxProgress;
            break;
          }
        }

        return {
          ...generator,
          progress: newProgress,
          collectionCount: newCollectionCount,
        };
      }),
    }));
  };

  purchaseUpgrade = (generatorId) => () => {
    this.setState((prevState) => {
      const generator = prevState.generators.find(
        (gen) => gen.id === generatorId
      );
      const item = prevState.items.find(
        (i) => i.id === generator.upgradeItemType
      );

      if (item.value >= generator.upgradeCost) {
        return {
          generators: prevState.generators.map((gen) => {
            if (gen.id === generatorId) {
              return {
                ...gen,
                level: gen.level + 1,
                upgradeCost: this.calculateUpgradeCost(
                  gen.baseUpgradeCost,
                  gen.upgradeScale,
                  gen.level + 1
                ),
              };
            }
            return gen;
          }),
          items: prevState.items.map((it) => {
            if (it.id === generator.upgradeItemType) {
              return {
                ...it,
                value: it.value - generator.upgradeCost,
              };
            }
            return it;
          }),
        };
      }
      return {};
    });
  };

  collectGenerator = (generatorId) => () => {
    this.setState((prevState) => {
      const generator = prevState.generators.find(
        (gen) => gen.id === generatorId
      );
      const item = prevState.items.find(
        (i) => i.id === generator.upgradeItemType
      );
      const amountToCollect = Math.floor(
        Math.min(
          (item.maxValue - item.value) / generator.valueMultiplier,
          generator.collectionCount
        )
      );

      return {
        generators: prevState.generators.map((gen) => {
          if (gen.id === generatorId) {
            return {
              ...gen,
              collectionCount: gen.collectionCount - amountToCollect,
            };
          }
          return gen;
        }),
        items: prevState.items.map((it) => {
          if (it.id === generator.upgradeItemType) {
            return {
              ...it,
              value: it.value + amountToCollect * generator.valueMultiplier,
            };
          }
          return it;
        }),
      };
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  increaseGeneratorValue = (generatorId, increaseAmount) => {
    this.setState((prevState) => ({
      generators: prevState.generators.map((generator) =>
        generator.id === generatorId
          ? {
              ...generator,
              value: Math.min(
                generator.value + increaseAmount,
                generator.maxValue
              ),
            }
          : generator
      ),
    }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  increaseGeneratorProgress = (generatorId, increaseAmount) => {
    this.setState((prevState) => ({
      generators: prevState.generators.map((generator) =>
        generator.id === generatorId
          ? {
              ...generator,
              progress: Math.min(
                generator.progress + increaseAmount,
                generator.maxProgress
              ),
            }
          : generator
      ),
    }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  increaseGeneratorLevel = (generatorId, increaseAmount) => {
    this.setState((prevState) => ({
      generators: prevState.generators.map((generator) =>
        generator.id === generatorId
          ? {
              ...generator,
              level: generator.level + increaseAmount,
            }
          : generator
      ),
    }));
  };

  calculateUpgradeCost = (baseUpgradeCost, upgradeScale, level) =>
    baseUpgradeCost + upgradeScale * level;

  calculateProgressGain = (generator) =>
    generator.baseProgressSpeed * (generator.level / 10);

  render() {
    const { items, generators } = this.state;
    return (
      <Grid container spacing={8}>
        <Grid item xs={2}>
          <Inventory items={items} />
        </Grid>
        <Grid item xs={10}>
          <Generators
            generators={generators}
            purchaseUpgrade={this.purchaseUpgrade}
            collectGenerator={this.collectGenerator}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ProgressBarGame;
