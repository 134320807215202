import React from 'react';
import OsirisIcon from '../icons/factions/OsirisIcon';
import KoralevIcon from '../icons/factions/KoralevIcon';
import ICAIcon from '../icons/factions/ICAIcon';
import GyroscopeIcon from '../icons/items/GyroscopeIcon';
import MasterUnitCPUIcon from '../icons/items/MasterUnitCPUIcon';
import AutoloaderIcon from '../icons/items/AutoloaderIcon';

// eslint-disable-next-line import/prefer-default-export
export const getIconForFaction = (factionName) => {
  switch (factionName) {
    case 'Koralev':
      return <KoralevIcon />;

    case 'Osiris':
      return <OsirisIcon />;

    case 'ICA':
      return <ICAIcon />;

    default:
      return <div>No Icon</div>;
  }
};

export const getIconForItem = (itemName) => {
  switch (itemName) {
    case 'Gyroscope':
      return <GyroscopeIcon />;

    case 'Master Unit CPU':
      return <MasterUnitCPUIcon />;

    case 'Autoloader':
      return <AutoloaderIcon />;

    default:
      return <div>No Icon</div>;
  }
};
