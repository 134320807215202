import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const headersData = [
  {
    label: 'Type Speed',
    href: '/typespeed',
  },
  {
    label: 'Cycle Data',
    href: '/cycledata',
  },
  {
    label: 'Progress Guru',
    href: '/progress',
  },
  {
    label: 'Eudaimonia',
    href: '/eudaimonia',
  },
];

const getMenuButtons = () =>
  headersData.map(({ label, href }) => (
    <Button
      color="inherit"
      sx={{ flexGrow: 1, ml: 2, mr: 2 }}
      key={label}
      to={href}
      component={Link}
    >
      {label}
    </Button>
  ));

class ButtonAppBar extends React.PureComponent {
  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div">
              Toolbar
            </Typography>
            {getMenuButtons()}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

export default ButtonAppBar;
