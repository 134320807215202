import React from 'react';

function MasterUnitCPUIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFFBogXvvVcgAAHUdJREFUeNqFm2msHNl13393
qareX/fbN5KPw+EyQ86m0SwaSZZk2QkcGI6BBAns+FMQwA5gBAiQIF/1PQGCIECQBAgMxZGX2Eng
BAacyI4sW+toFs0Mh0NySA6Xt6+9d2333nzorfq9plJE83VX3ap7lv9Z7rmnxMLKqhMAQiDoH9ZZ
EhUjpUIgCPCRyMFVBwjA4cicypyfdvSHOM7cMnyOm3gi4+GT50T2m+gPEmLq1Sk0uME840MzYH74
1zqD7+X41ZXfYiN/g45r8D/Df0Oe/CTxp590mtnsRTdBApO3u0lGs491biTWp3E2jWUhwDmH53lI
qUaznp5HCIEeMt+/ySK05aXFN6kWZjlyj4hFTKIMJdSE1s7q5pQ03OSPU3OPmBtdn4CTwLmnSPcp
Gu6zIQaMWnzfR/v+CHVD5rOPFUKghQCB6DPvOV5Z+AqXy6/QtU0AYiLsiIHTGnQZ0LsMaWeFMant
jFiy6Ji0qSnHeA53Zo6BWTqH53soz8NZy8hUMneM5hUCLQbS7jP/Za6WX8M6gxR9+5cuGUl79IAz
KOgTL8RppjL0udN4eRrzk+yKLIIyv8+O7T9Pex7a8wZECoSANDVYa9Dam0AiziEdDucZXl78MtcG
zJ8GmztF1JAgN5C4G/DiHOPvA6aHnzPCcGPCn8b86PqU88Mfw3uddShPoz2vP+dgSC8MWV1d5caN
F7DOItxYcQ6QVhpenH+L50qvY519OiHDyYa0ZxnPatFxhmmX+etOETDtcNNOZD4jYQ8uW+v6mtf+
hHCTNOWFF17kn/z2b/P3/u7fmfpsaYRlNf/MSM4C8dQQMqH1DGF94Zw2lDGhI01lmM8ibDrzjimD
z5xzzp2CfYY5KTg8POA//963aDSbjP3B+BkawRSPK07PMhZD5rQd/BDiVF4wVZWnmB8ZuMtMKcYq
HoVmph9uzLzn+bgBekfqcyClZHd3FynlgMazBMinPv004ac8uXUO7UtkoElt38nEDhLxNIqH0j/N
mBh6T8D1ETghfzH1M435adJSSiGlnKqMPgKeRuxZME5EuiSynFsIyOs8h5Ejjhw3HjzkfjHgXqlI
GTHMHDLzPj1THCQik4iYQlVfGUPmvQx6s9j+2WaVDd5yyqjxqafQmhrHTFUzM1Oi+fCvKdbfY2PW
Ej+zyi8WCvxKs00uSXBZGA8/T8X05KVpeh8xrweaz4wWgBCDJGrKY4UUmV9j/yKnTX5W5INbBFgH
ylmeXZmjHN1B6BAvL9mKCxyXTvjwgqI7u8TVkzbEPRCnHJPo5+59mzztcMUkt2cUMnB4vjfSoxhY
0FC4w3NjSxMkScre/v5Urcppi5CfJYjUGtY3anSPH9OqH5LLVzjOv8q2yXHXXeORUdiVTbpXfPLl
IsYJnE3BOay1tNoJSWIAOyBUnNLy4Nvo/CAuDXJ7b4q3zyKGU6BTSnJ8dMTv/pdv9VkY+Zv+AIkB
a+wIGE+LzwJIrKNS8ZnBEdXv4QWSqLDKwyRgRdznyGjSROKlu2zM1Xn9XJMr6z4q8LBC0u2EdHuW
0OTp9MCkMcIZhBB9RJwWRj9Px+HQvo/n+31aBuP7n4zGRTaMZxdHAiUH4ych1c8EP2t9QmoNQsgz
TI+sxgm0TJhZOodKnlCpxBRLgoZ9lpyEVlpmzh7xau4DEudotBvMBDGXi4/50up9NhYtIigjpCDu
tuj1UlpdRZgqjElwLh3AV0x8IKP5sUwytE3++5n2fDpLF6DKlblvHIU7gGMpvz5xq0CQYvjMfIx2
PrVahTrnWVWfgm2htE9ZHRM7yX48yxfnPqGWS9FKE3jgqTxO5al4dS5WTzg363C6SjP2ManBmJTE
CAweUmqkiJBSIMQgbjuH5401P6Iqq/3hPzEpnCwKxDDfEJMuUgjZD4MKzUcnP2KuuMy5/GWcMxPG
ZB3MlgRh8TLSSnAeQaBROkCnJ3yu1uRzHFDJt5G6REH0V5dSCJyUBKKIUgHnSxHn5x6w2azwwfFF
NnctzZN9rBVEaYBVZaSJ8GQPpTy0nxvl9kM0TNZdxiu90cmsBk/b8vCeTBTQw2WccYaOaU4Bj8M6
wXyug/Zv86B+AV0JcImgE1o8XULIHoXA4vnzxKmiEzuMBYRHEOTomJSZvESpCO3luLIcslT5hP8t
vsKF5SLNrmFv94BOGKNzBZzMo02IH0iEs6Mk/YxdTjB1ltmJrCMrjOEF4dDZApd4iu30A07MRfd9
ygs1lKyQJIBM8IMa+ZzC84s0W5bjtmOrkye2Ae20hPJLmLBMtdjl4nyb1WKTajGPcw7ruvzy9Q/Z
ay5wq7bKo92E/f1dWpHD9/OkQpPThpzvBvRNSVyFyzCYWTxnuc8A5HSVTfdT26G3Pb3KH9tQYjSJ
CMjnLK22QUlBTkMh30HJeaLQ8elxmXv1CnUK2HyFuYLCKslMZZUotbz7aJtbXoOX1o+5UD4iND1S
VebZpRbXVz7m3flFNk/W+eh+i5OTY3AWVSxjU4lyPXw9CJ1ZQUzJLqfnm6KfKLnsLaKPgPFSdnox
QyCwgPYAzyK0RyAU+VKOQBXZPdC8fzJPpM5TOzfDl+oHrMtjoq4miA1Hcws8uXCFixsbNPd2+MmT
LaKVLfKeoZeWmMu1kYHia9ePSeNdLi1f5vbedW5+ch9hY1LnYWSOJLYo28P3U5SUIORkojWV9mky
Gp/Q46RgejIkcWBShFZYaYlsTKQUiyUfT+fZO9C8vbtKmmouLHskuSILyROutXfZ3Wqy0WgjA5+H
x3s8+OrfYvG1N6neu8vNOzHMlIhMSi9tkUpNriBQec3PXXnA+nybR1tFmu0uQaBI4gQ/yCH9KmEc
oojwdYyUGiHkmRXtkPHs8mKaQ5DDE9l6XN8/SKQVSCvpOIURjljneWd7Bpwhl/PpdTU/eTJPHDq+
du9DXrn7MRJ4d36N454h1+0SSstxGnL+7gfc+N6fUpSWlUuXuFBeIOooosSj3rL0EolDYKzEALl8
ysqsZr7Yo9fpYGWfkySJEdon1TO0ozxRZEmTsA/v0yvRYVAQ2R9M5AMjY8reKq2io47YqnyXzeIf
sCFPKIgS75/8AqFdI00tNpXc36vQSef5+uZ9Xmu0OH+4Q6FZp+fn+XhhHennUBa0kDRTQ3z3LvW7
t8l7mo1rz7EQtWnHOVodN2BcYoHYCpZmJc2mR82e8OJKm9lCSqPXIUwj4jQkDLtIP0coK6SiShwb
TNIbLYgmsXCKQzFehZxxq8JJut59Npfv8M7mfd67e0Rldo1cThOLEriYbmzodCT3GovMJS1KjRPi
nEel1aJ8tEt9d5Mf+PN868ZrPFhbY98kvG1SPrYp87uf4YDq6jmqK+fpRIJG2xIbhdMKI8BIRZgY
Ipvy4OE2e1uPOedv8cb5HrW8IYojlLSkUQ+NJTaSLjViUSWO+lklZABxKj0QbvxLT66fBdbFtGa3
2HuyQ3R0wPX1BZYXztHrbmNsgnWaNC3xYL+M8asE+7fYKxXphjG2WuNTY5ip1CiWKlRrVf6imMfP
FbjkB1zAkvg59uMYLST5MEWILqkDoSROCZyTOOHR60ZUZoqcu7KOT4cn+4eUygmvXljmqOfzYN9w
2LZoX+OMQSqP1PngLeJchDQtlHQg5ISPGDvFftjXk3srgoSE8sZ11qTlYsUjOtzl4eYW83Me0jmE
Sem0IPUW8DzN61Lzgl8gSR1vlypEs3Ms1GZJ0xSbJrSDEun1t9j2PH4cR1SKBV6MUw6aTUzzAOcn
lMsxUUdwdOAxM+9QyhC2NJ12wutf+QfUN29RmbnPSaPOnbsPWJif5eeurrNdhzvbXRKdQ0uHMQkC
jzCWaF0jEAnCdkCYvmDdAPMZRExUhBwOhea43aLXs7zz0WP8fIG15WV828bzfKIYtFfFyZTl8hKF
j36IOT4mLwU1kSKc42DnCVoqDu4fsx62qfgBpjJDmi9iZio0E4c0UPd85nMFfGUpzSR0wzzN+5qV
i3W++5MSYdyjsrrG0pWrnDx5hLzzfVbCz+i1LLc/+ojFpVXmgyL7scE6h+8FxFGEkgprUiKhEW6G
QCR4fgutFL1Q4LCjLPpUScyh8ChvzZGsC15dWMekKQ8/eBtBD7exBvRXcKQRleUqYa6IlHV6nsej
pfMo69jZfkIhjrlheswcbPKV1OD5Hs1aje9Uf5WD3V2C+j5x2GU3Pc+8bKAKHVbORzS28/z0k3k+
uN3ll37lTQrFIkJp1p5/geVLV9i9dxPuvcONiuburuXJcZfybA7leWjpEccRSRTRbRwwVy2RqgqJ
Bn+5wtL8PNu3t4gSN4oOqlCufEMAhpT18rPM51bQHQ/RlmwdPeHj977P408fsDhXxVbPIZ1PEsV0
uyFy8Tw5E7K8/Yh3rn2Ou5deoBJ2+KXHd3nraIcvYTHVCt+1jhvG8sOrr3JYWQAM4mAHI+HeZ3d5
uB8Q2SXmK2Ckzx//r5jLz66ysrLAweEhhUIeqQRJ1AVhqaxcIlXzFBfOM1Mosbf1GC8XEMURzli0
lJjmY56/tMpJ09I8qVNbWWH5wgZe2OWk3kIpMV4Nnj6cslR6FZ43LxKWIo6KXSw+Oc8nsQKtPWKp
8KOQR4vP4L+csLV2BZWGXLp/kxeP9ugBacfwxdoslAX/de48+/kaXqtJTglE44hd7aOSEy5unGfz
SYtvPVGQdikUFNdfusZuvcG5c+e5f/sWs0vLrJ6/gGs5ekmXpctXuF6eZXd3n+XVBd774CbOgnEJ
znrky8u89+FjZK5K3OtS8Aro0gKzc/t89niHYSDUY/BnEgbACENe53h94Q2O5UMi5wgaD0n8ZWI8
vFyBzuEWSXmBdxYvUFCCud3PeGlnk9Ba8p5C4fgMyZNnP8fNbkyx2yFvE/TBJp1imaOdh1x5do2V
jQ2eyeVpNpo8frzNS69c57BRZ+2Zq1TmFqjMzvfX+A6U8gk8i+l0+PPvfZuiqXPQq3G8f4wu5AiC
PEloMbKIX8yRRCG12SrzGxfJk7KwVOV2MU8YxiDFWADDCbJRwTmHIcUrWXKuSqveIBfUEaVZukmJ
dqtJRfrUexEH9TqFRPDhwhLlNAEv4EFxlo+dx8HjbWZyAVEuwO09wuZ99o+PqFUE125cRxdL+PkS
peU1Lr7wIgf723j5OWbm5rDW4KxBJDEoiVQeBSl58PH7mOMP0bKHigpcWqny8MhgTIh0Pr6SWOdw
oWD92Q2USlgSkkRV8LQmJBojwAFSSZCZpfIoaPYLIlI4csrDxA57fEhRH9PUS3QPn+D8Mp3Y8JeJ
4Puzl9BxTByHhCddAtekEHhEzpA0digHAY0wwkaHvPSFN9ClMkhN/eiYi1euglbIkyOiJKF9dIiX
CxAmxUU9nLVoaTjZ3efwyS2WC0Ue74SsLXrUPz0EU0N5HmkcEwQ5et2QjavriEBwzpc4IzFpwky5
TLPVQYjBAltJie/5eMI/kxaTEQSAUgKSlN37t7D7dzDOont1cmEd6QytMOQkjOn0Qpzr7xilYReR
tglKFQ7bLbYe3eL81YvMLC4ihKF1vMXa+hphu0X75JhnLj2LjEM+ef99TJqiPA+/UsMKR9Lu8tEH
75LrbhG1D1ldW6bTK7DbEARSQuLwEdhWj2qtSOmZBd68cYFSkCdNHO1Wm+3dPZTqJ8FaCAGe41zp
Euu5Zwc7xH2OJ3qHRjs3AoQhUA7XeETaO8QUNhDlRRbjE3rCJ7FdjGmjpCYXeJTKs4TdNntPPsO0
tgg8w62bP+WoF/HGqy9zfnUD6RKO9w5Yu3iJxt4BF555FrMx2NfPz5IrFEmOdrl97yNcYxPPz9FM
Qi4E8MlWA5kvIaxCGSBJUNaxeOMqs+UC8+VZGpHC92JufXoPYy1a9a1f5SqFb6zNXOTNub+JL3P9
JGGUF0JKykP7MXlyA2E4pM6Tm5lFKIft1vHNITJt0rUe1suTVxpfK5w19KKQk50nJM1Nat4Rz637
1PICz7Q52dvm3tYePZmjVgyYn58FF9NqNvC0R75YIVep4AAFtHd2ufWT77DAPlYl1GZmuXv7hJ2G
T+DnUU6jrUPEKZfeeJnaxnluLMziZAGbpmztbHH70/t4etgaJdBGGJ4rv0og8hjSUytKgXDDevqw
BUUMGlQCgsoFvOIsSWsL0TukHJ7g5y6SegvsH+7QrR+BS5AC8D2axqclNFcvlZBItnZ77J084MPv
HvDgwXPceO4qr12/jNYBzgq8YhEhJQ7IG8tPb75Hof2AtlMEpYCo1WbvKKVQCNBOoZzDJTEzq/Os
PX+NpbzAVx5hauh1mrz9/vtopSaKqv2qsNA47NnGo1F4GApjmDAzKi8hKxQWZjDRIaZdJyjP4/sB
teI5Dg5K1E/qJEmMH3gIIXh0HNMKU168OsuF8x6X1wvcunvC0faHvHvwiIfbO3z95/8GKyurxFEP
oT0Kns+Dm59wdPcHLFcgFh4rq+f40ffv4VQZD4VKDL7WSCG4+PJ1qr4mlyY4ATJN+OCTOySpHWh/
XBBRuUrxG5dnXqKgSpxeGQ6ZfWg/Ji9yTMaIPg48T+N5AcIrE5TmEKq/byelpFIpIZVHN0qRMiBX
KCClR2R89g87dFLL2uoc1zYKzBU1cbNJc3eL21tH7PUstWKe+UoJ2+xy+8ffoRA/5iSGxaUZ6ttN
nhxocrkC2ioCqfCSlI0Xr7PywnWqLkUiCQKfx7u7/OTDm/hen7bs1piethcpEFhhUWjKlPsNUy7L
vMM6i9beoG5v+4ssJxkXVMEYy9JCmSpHfHYQ0400vvbRWuFUjr1Wyl++f8zakuDVK0u8UdIcHifc
fPghDw4fsfXZq/zy176KPNkm6PwElbeYpIgN83x8v4UXzIyY10lCba7K2nMb1Fp7rJfmSKXHsYn5
6x+/g5JqgvlhFXxqg4TFIlGUxSx6GBozWy4Oh/b80abF1GNQYLVINB1Wgx2W/GMUFlQBzy8gVY5Y
z/Ppdp4/+9EeR4nPi59/kbeuzbIc7mGP9vjTd24hghyFuWvUQ8HaSoHNzRMSVUJLH+0k2hp8Z7nw
2ueouISXS0W+cL7IGxzw/s1bHPZ6/fA96mnKboxMYV+iqIhZND4p3VEVQeCww1Y07U1tqhpqn4Gf
iGJDbW4NGbcpixZLect2p0o3KuPnC5gkIleoEroS3/vghId7n3L9fJ7V86scb+1ileKjgqHSLPJ4
06fb7rFzpMgHeWQKCoeMElauPsPMXJXnqiWurBQofvULfPSDH/BXf/B/yWs9pVHSYYcbI9mWNImi
ImdR+KOQKAaL52H7qdIaay3DeHCW/XE/jnCOjqjhzz+DaG9h4y6XaimNsMeTRgmjiggByvPRxVn2
GzF77zYRaPKlHLK1y/7NFoeA9UpsNqFYLqFii3YG5SylapnF69dYFilXqjmKNy7SjSL+7R/+HxIH
fnZTZIgA10/9M4shi0RSFmPmxXC3hH4fntb+iPlJXQ9RP55iVHoSEMcpMfP4lRKF6BAb7ZN3Ta4s
dNmu5+lEcwhZwFMBSmuUziGkBemQuQLWGpT0KMzkcN0QGxqU1GhPInpdlp6/QrlQ4Npshdp6FdY2
+B/f/H3e/nSPoi9HShq29w2NwDk3FEAf9iVRReNlkqG+/vudGXrA/JTeoSnNjqe3oASWyPjE3iqB
yBEke6Rhhwszhkavx3EyT2JmwfcxpPhIlBJIIRDCkMaGuH5MDkk+KIC1uCShtrpMZX2N9UqO9YUS
+vlrPLz7kN/5k79CqzESzzIPTjjkqAo0sPkzra4D5j1v3Hh8uofv/9PXPDaMgQPtqTk6pcsEs6uY
1FCbEVyqHVKTmyTdJs5q0lSQxOCMJokh6SbotMGlZYGJezhA531E3qGTkFq1RrBQwyn49//p9zmo
9wgyji/bo5j1B1ogKIgKGh9LysRy2ILwBvZvJ/alp/yd5gbPXhYDNCSJYl+s4JcKFOIGOdtksdJl
USVs7tVJ/CXQxX6RI3EUSxVKpGxvt0hdEZIQr+ixee8h1164QShg/6P3ufXjd/iL9z6l4A8SntPb
YlmyJf2qsERNwl4AFpQnsZ5GJKc23U/vs09hfrD7/DPQ0PfAialyIsuU7C6F6JBCSXDtXMxBM+ag
WyPRswipieKUOMnheQW0M4SNBrMXFphZnWW2Ngdbn/Cj0PLNH93GOtd3zy5jmqPu1ozbtu4pfYIO
pCeRnpy8gf6mQj8nsk8HQEZO4uzpCUGrgfPteCukapaCV8eyTyUfk9cH9NIeeycVUtWvXEoDLklZ
XF+m5RdoxCHf/t4PmakoPvjsgJ1mF18Kumf6YbI0DKLA6ZqgG+T7Q+bdQNPZXgQnBhUSd4ad4aOn
Iu/MaCdGEBGir4lU5tiKl5kvFdHRNjrqUSz2KBYEx12PwM8Djpdff4Nf/MWv43sed27d4t3v/iUn
HcV+u8+8EP0GamvGqJZSIqUYIFOMGlQnEeAc0lMDzZ/dYRuWDKcLdsDMKNti6uLKTfkxfmOnv3m/
36yQC0qIcJ/uUQerLLV8iMgVia3g8pVLHOzusbuzw2uvv8a777zL491dRH8jCGMs5Zkaz1+7jKc1
xlhu37nDyclJXwiZuXWWmLHmn9YtkqF2ILBstybZ7adJJY8ui6xosv3Cw29S4AHNRkS3lyNQGp8u
1qWYOMGkcLx3yHNf/AJLC/N0uj3CFMIwQmsFQBjF/O2vfpUvffENbt68iZSSnd1djo+Px4geTKnF
SPMSoQfefhw+M4PPMnhWq+MxQzRkAXK6f2+yi6tfoHAOOu0uURiipSB1GisqSOUolmb4jV/7+zz3
3DVmZqooKeh0u/zWb/5Dfu8P/pBbtz4ZlbqEEHz7z/+CP/yjPybwffK5AKX0mJjBGA1jzVs33jI6
TSoD2Y3fK+j/74QbwH5YLuOM+WRlOCHYbG+oEFhrabc6JEmCkHLc9gqEUcqN6xf4/OdfxfM8jLVY
A0Eux6VLz/DKKy9z65PbE0h66YXrFPI5jo7qvP3O28RRPGrRHTdLCwa7p4yMd1quN6Q0uyocCSuz
3z7q2RsQP+7kHN8kT3V5SikwxtBstEiSZHB9ch4pJfML83i+j7Fj5zZsoatVq3iDRY+Skr3dXZrt
Dif1Bl/+8lt8/ee/RpqmZ9CnJ71Rhu+BQ3LDatAYOJlmw4xFi0wTust0LE40bmTuHb2uJ0jihFaz
3a8ryCkr9MEibH1tFSklxozfa1JKsbe/zw9/9GPSNEVKge97vPfTn/L222/TbDWIoohXP/fK+KWJ
IeVust1qslF4ItKNYS+G4hhpUExoWAw6OeWgjjjW9nBcf79eIJBSEoURrUYL5xxSSE4pfiTUXBCw
tLQ0UX/QSnFwcMh/+I+/w/7BIYuLC1jriKKYn//KV/mFr38NKSSVcpk4js+8xxQnyeT7AmcyxjOG
nGH8lBBktl11aB4DwQyFIrLjpaDb7tJudfoQf+qbJn0E+IFPtTqDFH3Baa05PDrmm7/7LYyz/OPf
/Eesr69jjMHzFPfu3+PNN9/gX/zzf8YX33qLH/zwxxP8JEmCMdGUTNBlMOsmeR/i3Q2gPM4Kz6B+
BPisyQ2dnXOOVrNN2AtHzm5q1B1Zi6DT7vDOu+9RqZQRQtJqtfmj//bfaTTq/Mav/xr1eoMnTzaR
UqKU5M6dO/zLf/Wvqc7McHLSoNtr4+l+w3WaJpg0JkljRO3ckvv1Z/8pS8E6Fjvw4GLk2GJi/sT+
OxaYH6SQw9flMq/GTWk/O+PpB5o3xtJqtIjiaLq9T0UAWGtRWlOpVPp+I0loNBoUCnkKhSKdToew
1514Zn/pPrD1wfnUJCRJ1I800H91VgmFkh7SmbFzor9MMhg8oYfWPQh7YweZra+dyf+yIVFAHCc0
G62Bs5Kn8PJ05qHPgDWGk+PjkedXUhKGIb1ub2BWakL8fZ5Fn1Yc1qSkSUyaJKN3jbUSmncb3yHf
KTMRDQZbYk4YnEtp0syQ655SDHWjADKRUAyOMAyxxvULlKdtLHNMXM3K000a2SivH/DtJmqUmWJF
f3FAamLiJJ6IBv8PnsLQZSqnm1sAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQtMDVUMjA6MjY6
MzIrMDA6MDAc8WRnAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTA1VDIwOjI2OjMyKzAwOjAw
bazc2wAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default MasterUnitCPUIcon;
