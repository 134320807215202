import React from 'react';
import PropTypes from 'prop-types';

class WordList extends React.PureComponent {
  render() {
    const { words } = this.props;
    const listItems = words.map((w) => <li key={w}>{w}</li>);

    return <ul>{listItems}</ul>;
  }
}

WordList.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WordList;
