import * as React from 'react';
import CycleItemsDatagrid from './CycleItemsDatagrid';
import cycleItemArray from '../../data/cycleItemArray.json';
import cycleQuestArray from '../../data/cycleQuestArray.json';
import './CycleStyle.css';

const itemBasedQuestArray = [];

cycleQuestArray.forEach((quest) => {
  if (quest['Item 1 Required']) {
    itemBasedQuestArray.push({
      itemName: quest['Item 1 Required'],
      usageType: quest['Mission or Job'],
      faction: quest.Faction,
      usageName: quest['Mission/Job Name'],
      numberRequired: quest['Item 1 Amount'],
      kMarksQuest: quest['K-Marks Reward'],
      repQuest: quest['Reputation Reward'],
    });
  }

  if (quest['Item 2 Required']) {
    itemBasedQuestArray.push({
      itemName: quest['Item 2 Required'],
      usageType: quest['Mission or Job'],
      faction: quest.Faction,
      usageName: quest['Mission/Job Name'],
      numberRequired: quest['Item 2 Amount'],
      kMarksQuest: quest['K-Marks Reward'],
      repQuest: quest['Reputation Reward'],
    });
  }

  if (quest['Item 3 Required']) {
    itemBasedQuestArray.push({
      itemName: quest['Item 3 Required'],
      usageType: quest['Mission or Job'],
      faction: quest.Faction,
      usageName: quest['Mission/Job Name'],
      numberRequired: quest['Item 3 Amount'],
      kMarksQuest: quest['K-Marks Reward'],
      repQuest: quest['Reputation Reward'],
    });
  }
});

const formattedData = cycleItemArray.map((item) => {
  const itemUsage = itemBasedQuestArray.filter(
    (questItem) => questItem.itemName === item.name
  );
  const jobOnlyUsage = itemUsage.filter(
    (questItem) => questItem.usageType === 'Job'
  );
  const kMarksJob = Math.max(
    jobOnlyUsage.map((quest) => quest.kMarksQuest / quest.numberRequired)
  );
  const repJob = Math.max(
    jobOnlyUsage.map((quest) => quest.repQuest / quest.numberRequired)
  );

  return {
    name: item.name,
    rarity: item.rarity,
    kMarks: item.kMarks,
    weight: item.weight,
    rep: item.rep,
    kMarksPerWeight: +(item.kMarks / item.weight).toFixed(2),
    repPerWeight: +(item.rep / item.weight).toFixed(2),
    itemUsage,
    kMarksJob: +kMarksJob.toFixed(2),
    repJob: +repJob.toFixed(2),
    kMarksJobPerWeight: +(kMarksJob / item.weight).toFixed(2),
    repJobPerWeight: +(repJob / item.weight).toFixed(2),
  };
});

const instructionString =
  'The below datagrid shows all items and their usages. You can show optional columns by clicking on the ellipsis in any column and selecting "Show Columns"';
class CycleDataDisplay extends React.PureComponent {
  render() {
    return (
      <div
        style={{ display: 'flex', 'flex-direction': 'column', height: '100%' }}
      >
        <h4 style={{ flex: 1 }}>{instructionString}</h4>
        <CycleItemsDatagrid rows={formattedData} style={{ flex: 1 }} />
      </div>
    );
  }
}

export default CycleDataDisplay;
