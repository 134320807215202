import React from 'react';

function ICAIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFEyQlahssBQAAGYpJREFUeNqtm0mQXNeVnr97
35BzZmVlZY2YSIIgKYCzREi0JLelNsPdoWhvHOFh2TuvvPDGK4cWjuiFI+zwxuGwO0IdtuxoW3Kr
rZZpkSIpkZQ4ghQHEARIAFUoAIUCas7K6U33enHfmFWQQKkzAqiql/nuu2f6zzn/uSm+85e/1Pye
L6tSY/2nP+TKf/4zSotHIF5RC40orC7Q6MJfABoBWpB+OHdP/jLp3eLgilqYy4etgUBoDUIU19Fg
/97CV2tsv/Mam2++hN1qZw8W+W0WN539JbJ9iomFxeGXJ9V56EsfstSk8PHr91KAdBwGVy5y+6W/
pnfpI9zuLCizfKSiAxtB5yXS8fvmwl0cIBVA8xscVU98uCCoQCTekbP834oCovGY1f/1X9j79H3c
TjcVXqHQamLDOvdTADq7kGxKT372kFt/6+uQRXRu8cxBzUZ+NwUIiZCSq3/+b+lf/RRZrhgX0xql
FcPRmCOLiziug1bq8D0WrKZ/gwK0wYjCJZ349YTmzFrF9UX6h55Y93dSgLAsdBhy64UfsvvR22gd
IctVQBNpRRAEtFstarUqlm3fmwJSMDxoxYOfzTaf/K+zS4W1xIRufm8FCClRQcD+Zx9z439/D1Eu
IUvV2PKaKIpwLIe5+Tm01oRhGD9VZG4vQCuBiC2VGjMf5VrEgukUwbVIfh7uIel7eQ1qgRY5rEk0
ILLnyi9mfZvh6hWu/Kc/Q1YrSMdJVwoCH4Hg6NEltNZoreP3ROaFArQCIVRqtbsKL/jbF14Uf9Xo
e1WAxqrU6F/5lOs/+HPCcR9hWallx56HZdnMzc4at88Ln/qnRmsRP1hMCHFQeGOtLyC8Tv6JnPCJ
MlJNF4QHcW8KkG6Z0Y1lbv/8b9g7fw670UqFD4IAKSRTrRaNZp0oioo7zQei1mit0Mr8Q6sYoTUo
BToy7+s0gd3VIJoDGkn/1pPBnz1+4i59DxggBMr3WH/xr9h49Xnc7lyqWaUVfhDSnZlherpNEITZ
PkQWczp5eqyA7LoAIWKPUalTCiHQQqa+UrR+lhWEzr0X/6ELpadI38u8j9xi9xACVqnCjR9+j823
X8FqNNKKSgvNcDBiptNhqtUiStA+ET7xyMS6KkJHIQiBsG2E7cSYEJqN2A7CdpGWBKXQUYhWESrn
Eanbo4uunwifpMRCzaFjy+uJYsrcc3cPEAJpO6z/7EdsnXuVyBsa11dmofHYo9Vq0Wo2sWzLuH6h
0otdPgohCkBFYLs4UzPYrWmE7eBvrhPubGDVGpQWjqGVJtrfwd+6jR4NjLC2A9IGKbLFddGND62Q
csIXPGKiIjpcAbH79q98ys0ff59wsIddz4QPowhL2sx2u1i2Hcd9+lRQGqUChDaWtep1ZKWOPTVD
6ehJnM4cajxCXP4EAdjtGaoPPo7VaBHubuHdvEqwdYtwv0c0GqKCAKVDhLSN/QUFIQ64/oFeYLJr
iCsIcRcMEELi725y+T/+GyJ/hFWtp7KFYUQYRNx34jjSkiilcvWlyXNaRejxEFmqUJo9QvWRJ6k+
8hTlIw9g1ZroKGD//ddx55Zw2jMIy8ZqtGg98y2sSpVoPMJbW2Fw4X36F84xXv2ccNBDuBW0ZZNl
71zcpyV2spfE9QUH+oj0ngMhoJFuBW9jjeW/+A/4+zvIUimNaT8I0UqzMD+P7eTTXezuYYSO3b00
f4zaI09ROfU45WMP4s4tYTfaCMfBX7+Bv76KVa5SeuAMwdYtvLUVdBhgt2ewNVi1JnarQ2npBMPL
5+mffxfv1grKjxC2g5CWAdBJ4ZOm567CJ9hh3i0oQDol/K3b3H7lJ+x88Abu9Ewce5owNDHebDZp
thqmyssvqiJQEdKycOaPUn/8WRpPfYPy0ZNYtSbCshCWREhNsL2Ot7ZC5eSjVB84zTAK6J9/G39j
jfLREyBtrGqd8rGTuDMLuAvHsepT9N5/Fe/mNVQYGLGkzNrc38AZFOBiIntmChACFfhsvfsaN//P
f8OdmSFJIwpNEIQ06g3m5rqZ8El1FymIIoRt4XYXaX71ORpPfYPSwjFkpWY+qwyaCxUQbK0TDQfY
jRalxWP4d66jvTHBxho6HCMcc4+wHOxmm2q5jFWrY9Ua7L7xU8Y3rqJ83+QEaRtZFblGSOSKnURy
UsvnFZUqwKpUufX8D1j78X/HbjYzDQoYDca0Wi1muzMZ4KWlrYl5IcCZnqV59tu0/+53sKe6plpM
06NGex7+cB9vfQ3hlnHaXeypKk57Bllr4G+u429u4kzF6E8cYbZLaf449jenQAh2XvsJo+uX07pC
IFPhhT5QXMfCi4KXJP/bifCbv/wZG689TzDs4Uy1TW8vYDTyqNfrtKda2LZFGOWIDg2oEFSEM92l
8cTXaH/jOZzuYlIEGDdVEWFvh/Hq54xvXGX46Xs407M4swsIC+ypaUqLJxhdPs9uuUr5+IOUj57E
7cyBZYPWCMvCaXdpP/v30ON9lDdivH4dIg1xTZFluLzwCRU2maPNJVtYFoPlz7n10x8wWL2MM90x
msUgvpQWnek2lWqlGPcCkxbDEKtao3ryMVpn/5Dy/Q8R9v04ISjUoIe/scZo+QLDix8wunYJNdyn
9qWnsdtddAhWrUH52Cn6H77F7uvPU1o5Se2hx6mePI07fwyr2kDYFioMKB09Qevst/C3twj7PYLd
bbAsQB4OeoleDrG+AOxoPGb5L/49w5vLxvK5Mtcb+5w4fpRypXwg7tEY19ea8tL9NM9+m8qDTxAO
fNARsuQSbO/SP/8ee2++yODTc0TjEWq4R+3hL+MunkCWaigPsEq488ewuwv0P3wDb/0a/fNvUbn/
Eaa+9kc0Hv8qpYV51Dgk3PcoHTnF1Neew99cp/fr19FKIaWcKIMTxE9K8kw9SfGI0FjcWP7ucG0Z
LImwDaBEkcLzfBbm56lWq0WUTUkYhRoNcDqzNL/yBzSe+gZ2q4NWEVbZwb9zi703X6L39ksML58n
2N1CRSEoRfXUEzSf/gbuzAJaKYSQoBXja58zWrmECgOU7xEN+oS9bdRoiN2cxplugzJtuXRclDfE
W79OuLMV7138VuEhqZzjOmDnwzdxOzNIx4WY1AgjRbPZpNVqTtTiOTdSGiGgfOJhqo88jTM9FycT
Qdjboff+G/TeeYXR8iWi8RAsB4FGuiWczix2q42wLFTgIywbu9HC6cxj1ZqowT5CSsJhn8HlT1Cj
Idg205V/gN2aBQR2q03t4ScZrlzCu33TgKEWaRU7Wfkl7a+eoJhkaW7BIG5MYoRRhOM4LCzMo+LU
lbp9UvDE3ZtVb1J98FHKR08iShXzXhDQP/8Be2+8yGjlEuGob26P+QOr3jSCVqsxjigQIMtlnJl5
rGYbISzDOwqJGg8Z37zK7psv0vvgHaLBwCjacSktHKP+0JM4rY7ZnFKZ5Q9YP8dN5AqhrBsUgpHn
4TguS4sLKBVl3VWecDAAAWic7iLOzCKyVEYHHtGwj3/nJnu/eoHx6meE/X3zPGmhowi0wm7N4HYX
wS5nnmVyHe7MPE67i5ACHUaANGA82Ge8do3dX73A+PplosE+yvMQtoMzM09p7oi5B5XDukR4lWsY
M+ETgsZOhR+PKZdKzHSmcRwn5vMolpgJyRL37rJSJ9i5Q//jt01ZGgR4t1fpv/8SwX7P9PbSRusI
HXgIoXFnl3A6c6aAiVSaTRACu92lNH+M4cVz6FEfStW0jY52Nuj/+mXKi0fxN28hbBfQBDsbyHLN
iKo0QiYVT+L6IiuC8rxgrBUb8qzOFI1GgyAIDqSSSXpJWC72VAcd+IzXVtCBD0oT7G2Y7q/VQdhl
iIkN7Y+RFlRPPYYz3Y09VMVxqdK4rj54hvHyeYK9HUS5lmk+8NDeLsHOBqxeRkjLhJUQOFMdhOtC
GOQgPlNAkS7M18IaW2mN5/ksLizQajVj4QvMYcqoEMe+RmBV65Rmj1A+dhJsF+2PQSnKPEj94SeL
vJ9SqNBH2g7Vh57AbrbNFnJVIoCs1qmfeQbhlokGPYTjmqYnB2bCKSGkNO4dA7eQAvtSi2g8KhQ+
hjOcpMiKLJHdHwyZn5ujXq/GloB8h5fpIkZSYdpllKJ//m2sqQ71R8/itKYNDnk+haozWU9FIARW
fQqkBZOZRWuEENitDvVHnjZdpbQQCaDlPiddB4Qk6O0y/Pxj+p+cMwSO46J8P6ae5V2ET2SKu8H2
VJtWs4mUEpUSG/kH5n+YDCBdB6e7gHRcRud/hWULrKe/hTszZ7AgVClgZOVDjp3ReUSdUILtYE91
Ys41t49YCcK2TOxvb+LdWGa0fBGr1sRFGPLE99JRxIFnpMJnZbF1+vTp7+pYsMLDkmZHi6zj1IbJ
tap16qe/Qv3Rs4wvvo23+jnYJWStjtVsZShbeHqyzl2EzxlJIAwxmr8sRDySA+/2TfbPv0v/43dQ
/oipZ58DpU1RNOjFmUfmwj1GwZRuz5DdDqMwIzYKExwdT3CS1JJggI7TzxytZ76O026z/ZP/ys6L
/4Nov0fr7zyHO3skl4czfv43iF2wUn6YmRKsWiCkxrt9m923XmbwyTmc6Tk63/6HVO+/j3B3y5A3
qerzdLzIcCzxxrg+sA8fYmSWL/QQ8T8hLWS5ilVt0Xjya6hhj91Xn6f37stEg12mvvkdykdOIhwn
zf+FOL7XV2ITy0ZHEd7aNXZ++Tz7F97DmZ6j+fTXqZ36UrofYdm5G4trJIWPuZR5aJESKwwxiMdT
WY+dlpTC5HflR7hzLRpPfRPv1jqD//t9gp07IKB19u9TOvIAVq0Glh0XNl/sJWwJUqJGQ7y1a+yd
+znbr/4NkedRe+hJaqcexapUCXojA6xJxjgQZVk6nByoyMz6OaI5dQgdC69TjSX3mkyjUGPQ2sJq
trFa0wTbd9h64S/ZevEHDD/7CO0Nkbb1O3iAMIAXeQyvXmTrlR+x8fz3Gd1cRlg2wnFRoUIFiU2z
WcSk9bMsdggfkAqfG7UfFD6fSUzNrQLfDEEVCGEhyxVkuYJSGu0F9C++T+SNCHtb1B/7Ks5U18wI
7lV8yyLY3WFw4T0T859/TNDvm+7RLSFLZYSUMcxos58oLDICMeIfIkUqsp18sIAZKatK4bZkIaVC
osG+EUibzVrVOla1YT7olPB3NohGQ3QUYdVaOE/NfCHrA4xvrLD71sv0Pn6bcH/XDEgAq1zFrrVM
ExdPnaLBPjoIYkdOgPPw1fOyycMQOmkjslSmCxvTYUCwu2XKX63AtrGbbTPx0QphSVCaaH+P0fJF
xjevmhR6r2EQWy7YWGNw+TzB7iYqikzpG3ehTruTtvA6jAj2tlCBR1yppZJMDkUmD0nIfNWbWF/o
CeG1iBkUQy4q3yfYWkd7Y+OSlo091cHpzKVAKqRjQMmykE4JGbfc9/TSGhnHueH/pUH4mOt3pjo4
07MIxzWkbBQQbN9BjYeZsx8yF8jSoKkQNQJZHBkfInz+CIMQpuvyx/jrq6jxALRCSAu73sKdWcCq
t2POIELYNm5nHmdmDuF8sdM4wpE40zO4s0eQbillgGWtgdtdwJ7qGBZIa5Tv4d2+TjQcpB4wSYvH
dEh2PeUDNAVLHywfsxBIx9lhQLizQbC9YZogQJYqOLNLlJbuNw1T6CNLZUqLx3Fnlw51fyEtM+VJ
DlsUokDgTHcpH70fq1JDhyE6CijNHaU0fxyrUjcWDgP87Q38rdtE/jgjQw54VV4V2UsmiM8BxM+0
lI4IkhQBqMBjfOMKYW/XNDqWhTu7RO3hJxG2hQ4DZKVGaeG4yQChKohnpjoytpgsTnkAFSmsWovy
0n1Y9RYog/DVk2coH7kf6bhoFRGN9lOmWWsdn8gRResngxJRhHMNSHVousuE17kMkdZDQqIVDK9c
wN+4ZU57SBtnepbGo2dx2rMIAXZjCre7iKzWCylQOA7Sdgn3thmvXsbfum2ITtvNSt8oQpYquLOL
uO0uSHBaMzTOPENp4ZgprrQm2NlicOlDIj9I2+S7Cq9TXi/dSyEwJ0Mh7SPin2lpLCRaK8bXPsNb
X6V84hR2rYlVqVM58RD1M88QDfZw2jM4012scgUVBDGYWRCGeJu36H/6a7xbqzidWeqnv2yoLdvJ
pk22g9Pu4kzPYtenqD/yFNWTp7GbbTPKG4/w79xkcPWCqQGklYukrPY/mOOy3+yDiJ/7qAChYi9N
30sIR4G/ucZo5SLVk2ewylXTyrY6NB5/ltHKRZzOPHZzyoBVEICU6CjCX79O76M36b33Gt76dZzp
WaJBj+aTX6e8dF+sBDMNshtNnJl53OlZ6me+gjuzYNA/8Al3NhkuX8Rbvx57YYIlmRBF6+ebrNQD
DiK+QKNi4U0jFTOtkBIXWkA0HDK89CGVE48YK7Wm0ULgdhdxOvPG/Wv1NIMQhvgbN9l79xfsvvMy
o5WLRKMB/sYaUX8PNR4x9dU/pLR4wrA9gCyVcbuL2O2uIUwtCyEg8kYMr31G75N3CPt75rxi4VTo
pOtTED5hzmRB+NjXk4PLReGztjbBAeGUGN9cNqPt9esI2xyQCLbvICt1nO4iVqmGEKa/925dY+vl
H7H9y58wvHaJKAgQTgmtFKO1FXbeeIGNn/2Q0colk14t8wy3u4Ddmo6zjoewINjeYP/8OwyvfgqW
YYiS47NF4Tlg+Ywi0FiLS0vfTf097ws5eiBx+UIcCYGQFmo8QAc+Vr1Baf44wrbYfeNFpFumceYp
3NklosGA4WcfsPOLvzbj7eufo8aeaWosG60VajQk2L5NsHkLPdpHuiWs+jRWtYaQCv/OOv76KrVT
jyGw2HvvNXbefJHx+irCKccEiMjamskxWSxMYS4iwFpcWvxuPt1lHHrO+jkKKU82JrRTNOyjRkOs
WgO71WL7Z39F7aEnqJ9+DNAMPv2Indd+zN6b/49osAfSRtiu2XS8jrQl0rIh8vBvrxANB8hKg9J0
B7tRJ+z12Hv359QfegJv/Tqbr/yIwZXzaC2Qtp3z48PPEE0KL9IjMhPpLpkbZKGTE17kYytRoQ1R
hHdzmd1fvUC4t4V/5wZ2a5qoP2bvnefZe/917Gab+X/2Lwn2tvBurxFs3yHs99BhgLAsZK2O2+5S
ml3CmZ5ldO0ztl78n4yvXWT6D/4Ep90l6u+yd+4X+Ft3GF65gI6n16mM+cNSOV4jf3AiwTgdK8Uu
0GYUlZAXnFyHWODqpIXWmmg8ZLh8gcgbIpwyUb9H74M3Ga1exmnP0njsLPUnniXq7eFv3iLY3jDA
FwbmkFS1jtPu4nYXcNod3O4Ce+dex7u9xu6513GmOshai/1P3sPf2SDY3yMpqLJRQDGLUXRcEjoE
KdGeR7C+hvjyl7+iVWLxRCg9EfJk+DfRXpgrUWRqf0CWq1SOPUh58QSRN8TpzNM4c5baqTPYzTYq
CNDeiGg8QodBPBsUpjhyDacgSy7RYJ/hlYv0PnqL8c1lrFqT8a1rZt7Y7xkeM/7egr7LnjNs15AI
GU+wRBDgNtrY5mhNkS/RIpcV0mOmBygF0uNmMubgwxA1HhONBuyd+zmVk2doHj1J+dgDWLUGyg8A
jSxVzDA1cy+Iv2CltUZ5AbJUoXzkBOHeDt7aCrtvv4w9NUMU+HFrbBvhc+n6rsJr4uP5Ria1v091
7giP/LvvxSEgsmlSGiviHoVPLyYtq2K8fh3V3wPrElaljlYRtYceN0WMbVKWTGr/NMPGjHMUoaIA
f3OD4eXz9D54i8HlT/C3bhOOBugwJ3ze8gWgzsd9LmylxF9fo37sAe77F/8ad2b2kFL4biwKv+Ul
MO6lBHo0RAtJsLPJ/vl3CXY2GV/7jNLiCXMOsNHGqtZNm5uj2NRoSNjfJdjZxFtfZbjyGaPrVwi2
76CBaDREOm4q/IE4P0T49IcQhNubVOaWmP+Tf8rU2W8S9nvYOh//hTIxs346F0j59gnr51UtJNIt
o1HoSBHsbRPsbtK/cA6r3qK0eJzS3FHczhyy1jAgGgZEowHhzhbeneuMb64Q9nbMgekY6GSpYvaQ
O/8rEMUOLy34khmG+WaKAggCRKSZ++N/xMI/+VPCYR+kNL1AgQPMtcdFxM+YFhN05OqBgyyMRpiZ
vTJFlFIK3e+hr1/Fv7OGdMtgO6as1srgh++hPIMhKgyNiFKipTkAlU/DpmI9GPz5LkBIbSbvWuPd
WOXEP/9XzP/jP0UJAaUyALbOdJnDvRypeCgzmlFLqdYLFFSCG7EAQhpGRynC0QCGg4Num/8OgdYG
T4SI10qAIv4GaHpPrsafKFSzibbCD0NOfOk0iy6U3n/VTJGTyVAhhpOb87Vw+iCdbK8Yf6kCi3YQ
OskrIjkiAOlZAB1/m0wX1khBMZ0Kk0zpckBXtEax+58A8+S6UlQXFhl9co69c6+ZteMP2KBNipgg
DA2mZVxaQWhtZoZKiInvBuu4GlM59k3E8KDNuWNtx+vmYzm5O63BC2tmVWpSmuesldhJ6yxMYqOJ
uGdxXZeLFy+aMEw8Uyd8gBDpRtJ2P0l8+TKS7HNGgbFrJvGfE0Wn092JeVyyXmyB7Jxzvu6YPNsf
G0ZklUrWt5m6X+vMWOldad5PGjdp+L+cITUJBkwMRjlkAzr71ThBAka/DSMmasf812mycBLZzen9
OffQoHOdXjG5F/eZ3SIKm7btIiut42dIPXFzwZ2TRjAFhlhXcXrJa1Pkf0/eK+RjnR3ZndhzEn4H
LS8yJeZkFhR3LHJ4VcTCxHuKVEYeSv4/o/DO4H7a99wAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjIt
MDQtMDVUMTk6MzY6MzcrMDA6MDB0s7szAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTA1VDE5
OjM2OjM3KzAwOjAwBe4DjwAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default ICAIcon;
