import * as React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { SvgIcon, Tooltip } from '@mui/material';
import { getIconForFaction, getIconForItem } from '../../util/CycleUtil';

class CycleItemsDatagrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.columns = this.generateColumns();
  }

  generateColumns = () => [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      maxWidth: 400,
      renderCell: (params) => (
        <div style={{ display: 'flex', 'align-items': 'center' }}>
          <Tooltip title={params.value}>
            <SvgIcon sx={{ fontSize: 32 }} viewBox="0 0 64 64">
              {getIconForItem(params.value)}
            </SvgIcon>
          </Tooltip>
          <div>{params.value}</div>
        </div>
      ),
    },
    { field: 'rarity', headerName: 'Rarity', flex: 1, maxWidth: 400 },
    {
      field: 'kMarks',
      headerName: 'K-Marks',
      flex: 1,
      maxWidth: 100,
      hide: true,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
      maxWidth: 100,
      hide: true,
    },
    {
      field: 'rep',
      headerName: 'Reputation',
      flex: 1,
      maxWidth: 100,
      hide: true,
    },
    {
      field: 'kMarksPerWeight',
      headerName: 'K-Marks/Weight',
      flex: 1,
      maxWidth: 200,
    },
    { field: 'repPerWeight', headerName: 'Rep/Weight', flex: 1, maxWidth: 200 },
    {
      field: 'itemUsage',
      headerName: 'Item Usage',
      flex: 1,
      maxWidth: 600,
      renderCell: (params) => (
        <div>
          {params.row.itemUsage.map((usage) => (
            <div style={{ display: 'flex', 'align-items': 'center' }}>
              <Tooltip title={usage.faction}>
                <SvgIcon viewBox="0 0 64 64">
                  {getIconForFaction(usage.faction)}
                </SvgIcon>
              </Tooltip>
              <div>
                {`${usage.usageName} x${usage.numberRequired} (${usage.usageType})`}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'kMarksJob',
      headerName: 'K-Marks for Job',
      flex: 1,
      maxWidth: 200,
      hide: true,
    },
    {
      field: 'repJob',
      headerName: 'Rep for Job',
      flex: 1,
      maxWidth: 200,
      hide: true,
    },
    {
      field: 'kMarksJobPerWeight',
      headerName: 'K-Marks for Job/Weight',
      flex: 1,
      maxWidth: 200,
    },
    {
      field: 'repJobPerWeight',
      headerName: 'Rep for Job/Weight',
      flex: 1,
      maxWidth: 200,
    },
  ];

  render() {
    const { rows } = this.props;

    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            getRowId={(item) => item.name}
            rows={rows}
            columns={this.columns}
          />
        </div>
      </div>
    );
  }
}

CycleItemsDatagrid.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      rarity: PropTypes.string.isRequired,
      kMarks: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
      rep: PropTypes.number.isRequired,
      kMarksPerWeight: PropTypes.number.isRequired,
      repPerWeight: PropTypes.number.isRequired,
    })
  ),
};

CycleItemsDatagrid.defaultProps = {
  rows: [],
};

export default CycleItemsDatagrid;
