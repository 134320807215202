import React from 'react';

function AutoloaderIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFFAIRjT5NEQAAGrpJREFUeNqdm1mPJNl13393
iYiM3Cpr7+qeXmbfNJwZLkPSFIe0LIAybEuGJPjFhj+DP4IBA370o2FAAixQoG3ZAmzYhhcRsAVx
HZIzJGfhzHRP93T1vlRlVm6x33v9EBGZkVXVzaGjUFWRsdx7z7lnP/8U60+ccQACcICxBYPOFq8P
3sQXIVfMO8zcARqPlaN+ARCCE4er/jhXPuScK8+dw1Wvl+8KEAKx+C0HFJwyKI6Vl+unln+qp8p5
hFLLNVTz1k8KIcjzHN1csHEG1VL8nZ0/4sXwS3gETPMDbpgJIe0l0cfW9oilrnDCueXCFtdFxQBE
xYj6MycY4HCLa+V5NbNYnd8Bzjo830NKhXUOZx1gF1MDSCmZTMbomijjDCqQfHH7G2hP8172PTwR
cGjuIJxgwT/HY47Vpawy4dgz4uTTAlFJjDj+UvXJsrwrQLjGY+VV6xye5yGkwlpbSp612IYkivIE
Zy16QbxfEv9M+CrWGdpiDU/4KOHhVmT2cUSvXq/vOOFWryxu1OojGnx51HjH7q4M1SDe91FK46w9
xrzlWZO12jiD9OELO1/nmfBVjCsabHe/Zssft9hTWbGqr+L4ECcZdOpMp3xwDvyK+OZup3mGUwrl
HPKU16UONJ/ffpNnw9dXif/s9D1eK5pjVQZvQbhoipX4bNOJ5mlNvEP7Pkp7C8UrDbrla19+g3/1
L/4lTz75JIUxS0NbHfrS4Dmeb59C/CMIcyvrcCtnp/HMNek89oQ47Y3Ha8AjiddaL+yHqNRhMBiw
tbNHHMeEYVi+J0SleqXh1Vr4j2V405C5Y9cXpDfE+riT+IxCdNrApzmcxqilZ1klnoWXkUIym075
zl/+Bbs7O0gpEVIghcAJsXC/esU3fBZG4MBVxK6o9sKinZSGk2LwmSY7XXCaOx+UxFt37L6rtE0S
+q2S+AbRdRwghFjYhUcv5EQwU7osxzKwWazZnea6SuIfp06/6eGcRXsBWulyzkcFYrWSieX/FUYI
sQyETj1Ec1J3Qsxq4urNd0IgnFvZ7VqMxUoo83gmPFpWRBXk+GivsfOnhw4rg60EXI3Pj2fAgniW
O36MAashgsCJR9mAz2jlOV33SwmzpZ/XHs5WIbVrPnAy5hKArELr2gEtVFTweAYs6Kzj6EoKXENH
hXA4IRerEKKM5mpOH2fIZz2Ob2a980prnLOnPlt719oX4CDwfP77X/1vpuMxWunSbrnlirTL3WN1
0TXcwELl6+RiEbdbQJZCXhm8hcAvbeb/FyPKNTg8rxHkNDbANQY+6aJBK8WdmzdRUiKkbIhXpQJZ
lpaZkxBLET+xG/Wkbkk8IFxFpmjG8lWsszLRyV0VdXz9a7jiqth+Zeerd1fGq/+KkyZBa91YwGpG
KW/NrvLJ6D0k6jfalZVE5pSsx61Y0IohKysuCagTlnJhFpwBV4ArcLZAa1nu/CKxcQuDvHyvEeUv
L63y1i3Xt5ILFFjePvgbtK+51H0J68wpxNbW4+SOrW6yOHZjee6sLX8pQ9TcFAR+QL/XJ45TMmOQ
2sfzWgAYWzExDCmiewgUtS+piamjupX5RZ3tHXe97sTSHKCFEOQu54PZ21zsvvDIna6ZsLAhK8WQ
pXupX7DGoqQEYcnSHOm36J85R2EMz5w9x9bODuvrG5zb2eDD9y/z/pX7CM/HOocSAs9TSCXRWiPs
DvtXP1itJ9Si16wfiNLgnNynU1Lz6gFdfzrNFIqVpEWsWlhRC3qDG85hjME5x2BtwEE8Z7DxBJfO
XWR3d4/XX36F3mBAp93B9wOMNUzv7/P6F16n1b7JQRSR5wV5UYCz2KIgTSKyXCGEwBrLwg419L3h
g8qlujIFX+z0ilGo11q+rMVnyMRK/ymWEW09hnWkrkBpH+kc3V6fztoAHa6xs3me13Z3OXPmDL7f
Yn2tRydsIYUgzwusc1jrKJxBasW169e4eZTgt0KcLXDWInCYNEVr0dD3hu4v2F+L/mq41aR3UZla
CEA5hq7NwuPD0tXoCQHOGYJ2lyfOP8e5i8/RDkOCsEfY6eN5Hg8eHKCVZK23hpICmxfEJAS+B2iU
VvieZuYEJi/wPIOIDsgiTafXR+IQwhI5gxVByRS33KrVLWtscS3/KwS54x8X0qObBD6WBWK1LOas
4blX3+TcpRcIiIltgFMeeZFjnGFt0OPhwyHzJKWlNUHgkWUFSkoKY0nynHYQoKXC+S2GoynD0YRe
r8vmeosbN2+QTyOywrD+xLP4nQ2KLCVPI6SU2MKUkqkUTUu1ypBVtW/qf21KPkMovCwg1Fw3Jmdz
5wLS6xLP5+TSUAjAGJJ5QVEUdPt9rDWMxzN6e9uYLMZvtckLi8OSG4vNUtL5nE7YodXf5Owza3ik
BL0ul559ng9/+jPyIgUEmQqYpxOk8NHCEbRbaKVJ43kZghkHSiyl9IQE13lD5UVk+Yx8rAI8IiEX
ErbPPc/o4ICbN24wHKdopdjod/E8jzAMwBrGw0NGBwfcunWL//jnf8r46IgojimyHGcdR0cj5vM5
cZqRzMe4ImNyMMYmGZ0gYNDvVmm2w0MTpjkb7Q7zpGCcFCTzOaq9weZTL9BZ30TKRwRdp4RM9SUV
9Lr/3GIJg5Dnup9bIVShuOOuk7l5oy/gUMrnyRe+gLFQxFNcNufmx28TTcbsXXwagDiKGI/GjA7u
8db3vsvlX77Nzt55suiI2XTCeDxhNE0YHs1IjEK7nPGDuwS+4vBwwr3RnGB9i8HWWYQXkEQRHaF4
4/NfxHldrh3N6dsJo9mcYHeXtTBES4jnEVKqY0nisVi5cttZllUq8Bsm5pOjCR/8+P+wfvYiyXTE
+x++y8N7d9g5dx6vM6DT3+Lo8ID5+JBbN65w7YNf4EzGh+98n93dTbQn0O0zWNnlaDSkM9hAmphZ
lrN78XnCSt1MniM9j3g+QzrD4XzI93/xU1yRc1Hn4AI2t3bQrQ5TXxM4gx6NsBXJ4rgxPPZZANo5
twgwTvDheAcGx3QyZz6OSeNrXPn4Q6SS+O2Qzs5ZsrDLOz/4K3pnzpNrD5dnJDZn89x5sizj0/1b
XPv4Q5565kmCfkKSQJzmeMMHrPc6dMIQrMVkGUII/G6pApnSBGGIv9ZGupyg7VOIDlZA4Azcv42w
hmg2aTjBOiut2weVy6x7CVUAp51rxNKOKkBwiwhDVU2RFPC8Dt31AWef2cYqj3BtnUGrw1qvy7Wr
7xNFKevakceHHEwtngoYOEneXmcmU6LpmDh1fPzhx+ydT5hHGXnh8MMu2m6yubWJVBonc6yxZPM5
ftjGb7XI0y6f3rhFNJ0QtPto36c92OLSxafZWl8jFh7p/nvYaIqUiprkOsETp9k6AbosLCyTiNzL
UC1NwpRZkOHcGTbZhlYHFfbYaLXJTIErIoJOwMZaH2cNr732FS6/8xbK79IO+7RaQ4YzRxxn6CDE
R5ImEdpvkUxnHNy9TW4dxkq8JEaYhF6/z1q7gw0CrDGV0S6rvr31dZxQZHmBNhaLR8fvs757kU7b
4/4nHzEbj8FvJHWu6RiXzRm3TFUrFah33FqSXsp8bYRxCq/VY9t2yeM5hQTP92lpj0GnhzMt8jzl
zv4tAl/jnWux88QT7F+7SdgOaYU9ui5Bao1xAqE1eTKnyAJM0GE6HlEGtoosjbEmIc+fwfM9sApT
FAgBxlgsoHWbMGwzkxKLRFtHMrzHz7//v5AuJwxDPE+R1T67kbCspkGrUqDLDG2ZZAgjSxNiHEQR
k/GQ2WRIkhQoBcX5p5gO73H//h3yPGe7q3H+BsZpLu1tsdY/5GA0Jvck7UAhvRbTOEfaMq/Xvo/0
AmTRwmVzsjxH5BlFFpMkCZ1OhzSOy8amMVibo3AI5bG3u0NHQ6vdRfsBQgp8pXDGcjSboIOQNC9W
bNdqT/ikvder7S+BM5bxZMrw7m12d86QJVPCTsjuhXPk6ZwinRL21nh6/Qw/evt7pJOEr37+RQ6G
Q77ypVc4sxnyP777A5wI8NsBQXrEWm+DQvj4T1+iyDOiecx8Niaf3OVXH7zL0WQGUhJFc+5+eoWj
h/fZ3Ogxn46ZHE3ob+2x89SLxIXiys0Dwn6OpwRrbZ/ttS6e36I92CGKD5BClXmEFCulr4VOLHxj
VTFa3Kq6JRSW9iAg9aHbHzDYOw9OYE3M+PAheQoXnnuZD999izSdEbkOv3r/F3zulde4eec+v/Ol
l/hnTz3FmfMXuL1/gzs3r/PNb77JMIFZFHPrzgM+ufopVz75hGE2Z63fZ3g0xhc+Smqm0zmBrzh3
ZoursyH5dETSaiGkot1fA6kQKkBIW1Z6nCOKZqhBi6nx8AT4vsZl8bJYUbfkarU4mQvUzwjyJKPI
UgCKPMXEAu2HxNMRGIH2fWyRk2cZz5y7yN3RiPsHh0wP7nBd9/jlWouvv/YiDw6P2B/NufLRR4yG
D3h3BPPZnKOjQ4bDQ6LxkHg8ws3jci6Tg7WE7TZ2doQUlte+8EW+e/s+1hTYPK3SfYVLjhC+DwWE
3V2S8ZCiKMicQgUtTBggwy6elLh4hi1STFFgEUix7BOUEiAEgeejpS49Xy4QhSGJZhwd3KO9vkE0
nyIcJHHMYHvAw/t3ITVsnx3Qbvl8PD7k9p3bbKkOd+7u8e13v43XGXBrDvPbl4mmW9yfFAynKTbL
y4QmS5HWUDiLlBJjDCaLMUXB0cER+/4tWv4hXmcD5fmYPMeZDOEMeAPwBL3dSzz54qvYq+/xcDjk
XAC5dMzjBOl5OB/02iZd6RDxEbkpmCY5eV6glATn0Nrz8AONL4LSL6LxHDhaOCFJ4wQnocgKOv01
lFTkWcT61gYbG5v4sylawM3b93j2lS9z9dOb7Hg5hZgxO8rotrtMJmP6BjI/xJqYTq8La2vMhg85
So6QQlAYQxFPaPsCceFZnOcxihLa69ts7J5DB22669t4sjLWUtFqdWj31vFaA+LRZVwQULQlxvOR
ShAKaBcJFovTAdpfY3vgc/P6ZaT0cQ5k0PIJdYfX1r+OQBCokKKAta1tlFIUaUQRp8ynU4LuBrmD
KJrS7vj0t89gEIR+gDWOo4N7WGGYGJhOpoStACEkw+GILI4I43v42RFMbtEKQjytUHV7C7h76zoe
OWfPP82Z809z5vxFNjY3iGcj7l19n1v713FeuyzC2wxnDdc+/Dmf/upn5EnCdDokmh5RpCl5nDLK
LLdziNubuGCbyTjCURrJ2v3r0G/z5fXfZSvYo3AFWvo4mTEczSDXtDoee7sbOPkQkQzJk5RBv4/U
kiyJweZo5ZNZxfDwgCfPPk1LSo6GY+gOsFlMksyRQYcsi8gtWNUiEh6yv0U7nRNOxmW5S/lcvfwe
6urHSKn59OoV4mhGnhkK5/D8LsH6WQg6eG1BHE2Z3PsEzyVknsDIFsY5pMkQhWBzbY3t7T3CwTYP
b15DKM109ACHWDBB77bPs9U6R2HzRenLO9hBpzEZBovH1Y8+ZndvgyTJafkeSMs8M9y6fZd0dECW
ZmRxyjxOyedDjCtIoymucwaVJ+S5JU1jCmNQXsDey1+ju7XH4e19hjZFmIIiSxBCcvDgPljLy6//
Nkli+Plbfw1S4vltpFIgJc5YlN9lOrzP1cvvI4RjY3OdfqfNWkuw/fwXuXfnHs//1ufJraTIU0Qe
s7axzcM7HyGgBE85V4KkHHZR9HDABf8M571dhtmU/fgT0m6X6TwnSebgIAwtsdGYLCPUktQYhCzw
ghbXLn/ChfO7IDVrvS7pXBOlOSrIMBbsfMbdj3+J++hd0jQGGSBaGwiZYYqijOGl49NPPub2zX0Q
EiE1QimE0rg6VzEZaTQjTlKyPOdoGuNpze/9/h/R661z1z4AJDbLUC4liWd0OutkeVpGvrYM/vTJ
eocrjYaw7LY2OTBXmOR3mCYthAtwhJzZ2mXda3H9ow84Sh1RWiB0QGdtk3R6SF4YxpOUyeWP0elD
jiYRReGwCIoswo7jsnpsMgqCMty1pkRzWcNgrUcuQ0aHDxq9/ar05RwSizMJWTLDWFdWaITCOkmv
12F+sI8vq9aXUsSTOcPhEd2NXWxR9j2WEsDJok/d/++LTQQKoSRdT2NtziSKGY999s71iAvB3Zu3
Mc4Qhm1Sa4kt3D2Yk0xH5GbIRt+nKAyzeYQxBmstxmVYYyisxNhkAWWjSn56T5zl/IUnufzR+8t0
XAgQsiqMOrCGNMsqBqhSUpRgrT+gyH0KkaF1CZW7e+sGG7tPEM+nWGsW2SKOCie4UkAsfwZim5Zo
L0FHVf+wEyomUUbnaMzZ3W2Gd26T5pbze3uk8ZR2u8vw4ZBsOsRax6gISOI5QmqMKXDWYZ3FOrBW
VumqqkrbCi0lR5MpN3/wf5ddZrGM4kucn8Vmc0yRl43ZqmDbanWZ5/Dw9l102Mc5gVSS3pkLpFlB
fLDfqG+U48ql5tfEw0DsEIg2ltU2mXOOVuCDLLh1p4zQXnztczzzwnPoIGB8eIgE4vFDZpMJQkAU
RSRZRpykJKkhzQvywpAXClM4rBFYJK7qTe49scvahfNE/R10ZwBCIKVCCQcmRRYJyhVE84JZUu0+
ApxhY32dvDA8eDBkfXsPB9wbjkizmOTwOpPRA6TUK0gxvax0lz/rYodAhJRJ6Crx2vPxtCa0OaMo
4zASbPY7hO0OkowzymLyiMlsAkVBXuTEUUKRN4RMqIooi/J1BXKSeJ5mfb1Pf6OLMyP6OmDrwiWc
SBkeFQRBi1YLolSxvb3FbGwopMJJDyEcFIZOr0vu+3zhG79Ld2OH2/dvM7z1IcnRA5I4QQqJVLIK
/SVSytIG2IXY7+CfQrxtQNGMdYQezLoedjJmejhGKI/A8+h02ly+uo/JC8LApzCOwkl0oNGeImi1
aIUtlJb4WtEKW7Q77WpRZXJT7ofi6ScsAoHWHfwwwznBYM3jaBSRJQ9Y324zHHeJkxxrEkDQCkOe
vvg0WZFy7Vdv8eD2pyRJ6V6151VdparbJyuUWK3jg8bOL9FdZcrkeTUyo0aKKDb9mIPOGulkTBYn
bHQyboxz5rMpvcEAT0p0GHBuMEB7CikEnu/heRop5UKqlirYGB/Q1U5Z6+j3/NI7WcfaoMT7OWs5
f+EM6xsDjo6mHI1G6KDNtQ9+zN27+8RxhBCqRIXUUzTsiRCld9ESzUDsoPFWia8Nji9RQq3AZCwg
CNloFTyMNC6NOUoVvrY88+wl/FargrRUxLoFfKLCVxzDGlX5eRN2546tY3m+tFm+p9nY6LI+6JKc
3SK3EdevX8Y5UMpbjCWatbF6wior1C3RxhctCpetegPnUL5CujLyctRIkXLyuvS8s9GCjWAJP1tw
uxzDLkDLj646H4PtnKxciQZnWDZqa5CUENBueadDHo95uYUpqmFyzcopjUGVp5BaQoUhWmGAa5Kz
hKo5cQwI1+wsHe8yHe/TnQZzq64l86gMYCpAhK1gOrLS41XxWGniLwofsITt1DQWNj/WG6zES3oK
ocUqR2tsUAMvWE93csPESYIfheNb4HaOcafCLMWTOVmSNZbh6Pf7CCEYj8eNOKEBmePY3KdsgHAC
K6tIsEZWgEN5CqHlidXWOMH6V9RMaEBnapRGzf/TQJw161aF8jjupqxNRpM5eZZX9T0w1vD5117n
b3/zTYQQfP8HP+Ktn/wEKeRqg9hBYYrFJaVUOcbxTRKuURO0DhWUYe9K3axWe9cwYM5i7QrarCHp
DczIMbrcqefLxkVNvCkM0WSOyYsF8c45pJC89uorXL52nSRJeP21z/Gzd96peghiISEWuHTpSZ44
e4YkyXjv/Q8wJl8phdXHojeofIVUy8b5akW9gcJfACXdQnqbFAgnGrIoThnrdJF01a4UWUE0mWGN
XRJPGT4rP2A8nfOl11/FWsuVq9eQWlMUOapya2mS863f+xZf/+qX2b9xgzTLuHL1KtNJVtHX6BKJ
igHKU1hlTupos5HYdE3uFCKOMWLJuFP667URXaiIQEhBnmZE43l5RS6tl7GWs2f2+Kf/5B/z1JOX
6Pd7CATn9vZ48uIF/vw7/479/RtorSjygpeefZb/8l//G9/7wQ/LyDUMK3fcXHbJBi20QChxgviT
GLvlDdGw3KsaXPP25He+Vk9PTpbFKfE0ag6zeNway3PPPcurr/xWyZAqpe10Orz80ou8/NJLfHq9
TnQsrcDnH/z9v8dLL77A/v5N3vrZz0o1EauGtlQrPsNR63n9fb5TYOdLRNkxa+xOitWKsAhBGiVE
0/kpnC/fV0qyu71dQmMaX4ay1qKkYn0wQEm5mMo6x8ODQ+7eu89v/62v8M1vvEle5KcStvK9wUbP
cBloNBZaQ2pXdqlpCReGoOlvjgtCDVkoU9hkGpHF6Yo7WxEY52gFLc6e3TthxLRWfHp9n5++/U4F
o5UIIM9z/uYHP+RHP/whw4NDvvq1r/I/T0NJ4ep6wImA6aTfbBJW60Dz/nGc0qMkqYFLjicReZqt
RpAnBMDRbrfZ3tpaCdi01uzfuMG//jd/AkoyGKwzHo9xSKx1rPc6+L7HYK1PljV3vySsMIa4SNAr
AUjD9Z1ceSPgOA08VHFwYfdPC4aqhMRZRzSZU6T5Cf98ggE4wna4CH6kLNPYm7du8Sf/9ttsbm3x
j/7wH/Knf/ZnjEYjtO/x43fe4ff/7rd440tvMBis8e//01+WqNVK8oy1zLOY1KToFWsDj4mnWULh
H0V/UxxO8RR1uzsezylqH/9rDikko9GIt3/+c77yxhsAHBwc8p3/8Bd4WvLHf/gH3Lx1iwcPHiKk
INCan/zkJ1z+6CMGgwHDwxHT+RRPqwXxsywiNSlSSMTvfPmP3Zvrf0Bx4mtzjoCQvzb/mYd2n5Zr
4x7lJ5tWzS02euUxIaDIywDnpEV+1O6Xf621aM+n0+2BgDxNmU4m9Ho9glbIfDYlS1OkkgsZbBpL
WRVIbU18kSKpkyEhULL6/s0xeZUotCg5t9itFR6IlX/1ea0GC8MqIU9z4skcZ8uI7rMcNfBVSYUt
CsbDw4Uk+VoTRxHRfF5WdpRa0TglV+cw1jLPI9IiQyKX5uyl199we8FFrLMr3ttVDHjobpO46JHf
J1jNcVaxGIurzpEn2UISfi0ydTHocYjr8bBzNYR+3MhRlpCkydKVV+z6f1LhnAQ45T78AAAAJXRF
WHRkYXRlOmNyZWF0ZQAyMDIyLTA0LTA1VDIwOjAyOjE3KzAwOjAwXWtVBgAAACV0RVh0ZGF0ZTpt
b2RpZnkAMjAyMi0wNC0wNVQyMDowMjoxNyswMDowMCw27boAAAAASUVORK5CYII="
      />
    </svg>
  );
}

export default AutoloaderIcon;
