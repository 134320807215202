import React from 'react';

function OsirisIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFEyQm8xJ9vwAAE2BJREFUeNqlm0lzHEd2x38v
q6qru4HGDi4ASJGSKGo4MmfROGa1T774M/migz+AI3zxweGbL47wwVaMwwrPhGcsaqyRbIqLKFEU
KUqkKFGkuIBAA91dVfl8yFqyqqtBKNwHgl1LZv7f+7+Xb8mWv/n3TeU7fLp9+Pxj4e/+KiBNhYVl
UG+EzFqSJDlwDIX6SwAi09f+vx+ddcn9K0B42LFEoNOFW1eFd98yBKEQhPU5RpMJKsLq8jLGGFQV
VXUvt4FuLu47CkCrFw8tBG1cPJQAxIBaeHhPeO8/DH98y7B8BCcAdcNlWUaqSjeOWVlZITSGzNpK
egcschbw5wmkjUnFN/G+6JQUqiuHEkAQwN4e/MvfGz770LC4Biao5rbW8mx/n9WVFY6vrYEqSZoe
uGDJWdEG8kDg+T2dIdTiXaHFAtT/o7kJ+E/J9HzdPtz/QrjwpuHOdYPNIJpzcwswTlPGacrK8jKr
i4tEYUiapu3AcgAiUplHy1Mz8avWxnneM5WJ1EYvgSqKaZOQ0xAYA08ewJV3hHd+bbAW5haquZMs
I7WWMIo4urrKXK9HkiRoDrLQMiI18DPXr9X18t3mIyIgMqWrmlCK+dq0KhV8OMAEivn/858D3v+N
YX6psnk3l7I7GtHv9zm5sUFoDGlO+0LD5WIatLc2dxwiiFS8tOq049jdYrf5++LpsdC0eM623fEq
KiAqlU9QbRGAQqcHu0/h7TcNH70vJBPozlXyTK1lOB4zGAxYXVoiDkOyLCs1Xy6goHn1lSwFVcEY
xUhFd7WKzXINibMvI5TeTBGcrOrg8cEXzs0Hnz/shOSDd89NCSAIYfcJXL9ouPBmQJZQ2+tTtUyy
DBOGrC0tsTgY1GjfTkvBWreTqEIYKUHowKeJYDPnaOOexVohTcU9K4DR+ogFeql/rUhdMaZ6SxGV
xsJoN4EghEsXDG/9Y0AYQhx7Y4myNx4jJuD01hZxp1MH72nAad4tT1XIEgBLb94yWMkQgeF2QDIW
krEQL1mOn07Y2zHsPAkYDQ1Z5rRmgpz2WmG36jQv/nwt8YaKtmi+MuOwuBh2wFp4+18N7//WMN6D
+cVqu1OU3f0RnW7M6tIy3U4HUKwqQgHeqaWyX0EVbKqkE5hftJx+bcTx0xN2twNuX+0y3DYk+9Db
zPjen+6RJsKdT2I+/yhmuG0wJseVO69CkdL0MW3gneFM0b5YYxkJBhGM9uHODeEP/2Z48KWwvJ4D
V7BqSbIMMQFLg0XWlpZKzddZVTkXtQ48CsZYltYtJ14Z89ovhqxvJdy7GXPv044zqwQ6HcvWmQlz
ixmDlQxQ7t3ssPs0IEtyFphKCKXmZ9G+FNA07bVpAkEIdz4R/ulvAyYjYWGl/tA4TRklKac2N1mY
myMtaE/T6xbaF7JUsJkSRpbBSsr3f77Ha7/c49jJhCQR7t1yjHOm4mwehJXjGUvre6xupFz7Q49r
f+jx8MuIIBIkUEwAJveq/k7TBF8LtBq0l1JdQtidgw/+S3jn1wHDZ0K35xxSMd5wPEJMwMaRI8z1
eohAZgvwiqp44MGmQpq47W1+KePoyQkvnt/n5R+O2XwpIe4ru0+L1TT8kkAYQmfesnE6QQT6A8ut
yzFffdZhf2hIJ2CMEERa0aGF9qgXAnvWIbk0ylD4xgfC+78x3LgorBx1wY+jvjLJUlSEwfw8R1ZW
sDYjzbIGlbQ0Fc2cpw8Cy8JqxsZLY1764YizP95n+UhGFDukalvAU91TFeKesnVmwmA5Y2E1I+oq
97+I2HkckEwEUqn8g+fwfEHMsI7anfAf/jogCITlI3UmpTZjOB6zceQo68vLZFmGdTx1jkUrGro9
XLCpEMWWlWMJp74/4qXzI06cHbO4bgkPmXeWgU0AUQArxzLCaEx3znLzUsyd6zEPvwzZ2zGYEMLI
xQtq8iBqBnif9n5SEE5GQm+uor0I7E8mJNZybG2dpcEAESGzWQv4XGPWRW9xP2NtM+HcT/d44dyY
IycSBiuZG5siijsAeeMjOJNYXMuIOpa5Bcv8khPEvVsdJiPjhG+cKXxX8CoQLq1W3h6cw8sU+r0+
6ysrBMaQZqkHvhrZZmAzMEaZW8w4ciLhxT8Z8b2f7rF6PCXuKyJgrbNJMTOxttJAcwBBqCyuWrp9
S9yzxH1Ld87y1a0Oz54EZDaPl4zWTKJuZjMCoVo1Ry3D0ZjlxUW2jh5117JpzaOCtRa1ghGlN2fZ
OjPm/J8PeeVHI3pzignJnzsc9WdTA2wmqAhhrGy8lLK4lnFkK+Hib+e4eTlmb8dgc6E1wwFBWzUP
IJpvgyIwSVP2JhNWl5dZXVrCGOPi+yKY8LY6m3v7uG9Z20g4+eqIV17fZ/PlCXNL1k2nUnM8M5Rc
V/dBpFCXTIWhMr+knHw1IYp3Wd1IuXEx5tuvQsb7Bpu57bJKeKfBe/mXE8AkzUispdvtsba8TD+O
maRJqfkmd9Q6rQyWMk5/f8Rrvxxy4uyYKIY0Mc+neDtLn/+shSxfz9yi5cwPJ/QHljSB8UiYfGXI
UghMAf75ExmLsj8ZY4KQU5ubdKKorOYUmq85vTywUAsLaymbZ8Ycf3FCELrg57AfIQ9IlO8mhVIY
ghhYXLOceCVhsGyRQJ3JlWF5lTGpVNovdxoU83R3yPzcPBvr60T5VjBFe6GMnQtHqChRrPQGlt68
5lvoIQQgVWzvK0cBTB7uHlKOIm4b7M4pUcel11XYV5mWb/NVMulQml6vx/LiIgvz895e3+ZKtXUB
YvLg6dCqc+aTpUJmpRzHpcZ5veA7MEHy+oHUFT79XMMeykjwzAsvuCJmklRhJNQCHfzrxb1CrN4W
evBC3comY2G4bRhuGyb7eWBqIJ0I2w8Ddp8aTGgJAg4lCa3NXybL1do82oO/K7gHTCAVMMnBaZ54
VxVWqc9IBf6wnyB0yc+j+wGfXuzy2ZUu248CEIj7ynAn4MrbPT5+v8uT+wHGWIw5/PjVgjx40k57
3zzCNEu9PomPSmqaL4kj9ckO1npuz0YZ7ggP7kTcuhxz43+7fPVZxHjfEHWUTldJxsLn12KyDNIJ
JGNYPe6CHj+YOpwI6n2Bg7QVFiWDIqmpFwwa4MtxisrPIRakSjZR7l6PufJ2n08vdtl+HJBlQtjR
3OkpFhiPDbevxmw/DHj0VcTrfzHk+Gml26vt5jPmmTbF4p0m7X0dh2Wc7G11TWw6Y+rn9S+yFHae
BNy8FPPppS53P4l5+sj1FE3uPMlZJUaQTEkS4cmDkJuXnL84+/qIk2cTFlYtYZkCP1/wVb2wBbwX
LYYleO/NFr0fxKJpsePAbz8M+OKjDpd+N8e9Wy6fV3WJV1ndyV8SceU3BNJUeHQ/YvjMMBoK6UQ4
86MxgxXNE6vng59mp7Z+C/16WrHXF0gcMaU2aBEDzOK/K1Upydhw//OIa+/2uHujw3AnwAQQBFr6
hlqBOy9zmUCxVsgyYXfbsScIlfWtlP5ACcNZnSOd+i7evz7t/SJv2A7e132xl8zYFVokIAKTkbD9
KODxNyGjfQM47bn9Wj3H68fpbi4jQOD80PbDgId3Xf7vYoTZwq9coF/1aQHvtrsqGZoG3/zSNuls
Igou2HElb7eXmcClzX45qm2ogkFGQEPXNxjvOTM4VPfc99WNgavKk5Z/wueCbzBEaXiGGYsS0dzR
aR4EVTsxDadUlLKa9XsRVw02BXOQcs86+FNnVpP2UpTwiu5TO/gi968qqX7t7vlhgEz9v2ZWeQhZ
lLil8C3eRq7NSVqZry1XWsDngUEZFRY+Zyb45mCNB7X97szPNO3rLKrd94Mt9YSgs8b21ztrAer/
KT9m6qVGx6XKMabFPyv5mw22Xq5RkVqc3tyjp9g50/c2bkw5PS0jw9qaVQlbaU/d5utA2mtrBwm+
bICUgUieBKlvVn7y5R61Ktiiw3SAzZXmqbmL9eUs07QvfQ9Ca7px8Nmc59Wu3BMmcPWCTteWvQab
VSGr1Exg2swKwYWx0u27sURmi6B4UZvW4oXItWv5f025gIYGWrUveR2nLfBuAOh0lcW1jLWNlMFy
RhCoa5d5nRz1xi3n9troRpSVYylHT6XML1qC1t5Cdfqkuc1B7lyLgkj5XGXYYRvtp/RcE05x9OWg
/EDo9pUTZ8cYYwlCyyf/0+XR/ZAIICwlXz/VobhWegZilP5CxvlfjTj/q31Wj2cuF2gJIqYiFBFM
LpCykVoDXzmJsLHydvBN9Rav5/G7CZQsK45huEdMoCysKOG5idNmADcvxTx9GJJOBBsoQehtkHny
pBlEsWX5aMrLPxhz/lcjTr6a0OlO9UAdhY2bq8gtnNbVr9d4mp8WXngY2pfsaBx8sKmr5JTFUE8V
NnNfen04+5MxcwuW/sDy4TuOCVnqav3FCRDNIEuFTseyeizl7E9G/PQv91jbcG01bdFFUSdIJlL6
Fx92meprIyP0Qrrg2ObmG3IQ+FxyFXiH1HVsoDfnGqFzA6dRtTK1SBNA3IP5pYz+QJmMhL0dw3jf
zZwm7lhMr285+eqE83824rVfjFnfyog6TO1ybky3lgd3A6692+WL6xHDbZcqSiBFLbeV9lVcIQTH
t7beaN/u3EzVyazqfacN1/WxmRNGt2/p9LTVUakKUUfpDZS5BVvWAMd7Qpo4bzC/nHHilYRzPxtx
9vUxx0/n4H3NC14BVXhwL+DGxZiP3495/HVIMnGl8tIcavFHe6wQbGxtvXFgIJSfyasdOqBIeGB/
x7DzxOAcn6U3sGUPsKY1kx+E6jshRLGSJsL+rqE3sLxwNuHcz0ec+fGE9c2MqKNTZfYC/GQfnjww
XHs35tq7Xb6+HTLeq5Iu8aKrSvt+/iEFAZ4TCDXO+FV7p6ImF8DQ8M2diNGey/7E7LO2kVbULdLx
vIMcxcraZkbUndBfcM5rbsHy8g/GnH5twuKaJWyCz+lsrasVfns/4OM/xly90OWr26E7kOH3FLRS
VN3m3b2p3mCdZ/W00auh1p4rihrWwmjPMPoicqdHUjj3sxHHXkjozTsg6r1anDpfPeZa3mHkAp0j
WymL6xYT6FRfsZhrMhTu3wm48UGHy7/v8c3nAeOREHaq/sQUhto3P9Z2f8I22hdWM0X7hpAkcJPa
DLJEXOXnWU5Foxw7lRLF9e1L8/NAIrC0blk+Mirv20zK3cMHj8J4BA++DLj+XsyVCzGfX4swoTOp
SvNTVYDCWKt0vNYcVYLjm5tv+JFY0+Z1BviadlAkcM9ORsKT+wHjsdDpuWjQhbGNgkZO1QJ8WzdZ
BDo9ZTIW7nwUcel3XT56r8vDu5E7PBGDCWeB93OWvKJV9gmqp0oTaAsjqxrCQemm04BRiCLn3R/d
D7l5KUbEtbtOnUuYX7CYQMowt4jHs4bGi48xLhrceSzcvRFx9Z2YTy+54zGTsdDpWsRIYyWzaa/T
D+QCaDo6Py94Hng/rc2zsCBy9vjkQcjH77k2mMgeJ88mzC1ozTHO+hS+JdkXbn8YcfWdLp9c7LDz
JMBm5OXxereq7vD87s807X1AYe2Ya+H0Dgm+jBG8MNMYt7g0Eba/DbhDh7CjjPeEU+cSBiu2BKDN
0yO5t1cLu9uGL65HXL0Qc+tqxLPHAenEPVCVyHzw+QDFaouArQ18uSFIwwl6oDiQ9t6aa+66cqBi
nFN79ijg0w9i1/lNhBfPT1yTI2zUFvKJrMLOE8OdT0Iu/z7msw87bH8blKWIWvtcD65I1c9Ha8s9
JfRpXzq9WVmhJxhXzWlJa/MrYQjWuCbH4/sB6aRLlghBBCdecft91KnXWGzqNH/nesiH/x3z8Xsx
u9sGMeL6CcEhwdfYOU374uc2ghKqtaXF2Fyj1RweqNoJbGmt5hTvlFmZgTB0hYydJ4YbH8SMR4bh
M8PZ10ccPZliM1M2SSZj4ealiEu/73LrcofhrjssHYTqDk80wftHZRvgZ9l8XXG5CWhzEGbTXqXF
a7f8AKrcQQwE4rS7t2O4cz1C1Z0et9mYYy+kmAAefR3w6eWIqxe63L7WYedpQBC502YS1M1FG/PW
wM/EkBfOGttlOLXXe7/vmRKKlxi1VQnEf67Rb4y6UobOtz/sMNl37a8o3iMI4daViPfe6vPljYj9
oSHq5hlfXoWqrc0XQIv/kinzkKom6KfGAmHR/SsnmHUCuwDoHZqq3WsKz7+Xh3MuXHX1gwdfhlx5
W9jbEbIUvr4d8c3nEZOxyZ9z483sWzTXV9p1i+bzwmhl/9WAYW3A5sAtv8NpmotPx5pgfGHmCxFx
kZvNXP7w4K4wGQtpAsNnhsl+ldG5mlkFp2Z6M05RSXNOqfuAcleQ/IeeaUbYKuFCCB7livDYX0Br
Jcl/z3OWUrxW9AKskkwMD+9V0aGIIPlxVy20P9MZTTte9dfuN981T4u9kNlaJcnSRijcSMG0bUfI
69Xq0XPKH2h7AaqIgY1xhRZ30tw9Z0y+0rxz7J/qagvTZ5byfFNWvF6i1qkfBpigS6hNyvuTtv0O
r+GItFZt8u/l0Jt5rf9fpbJzqbamKVa2bXc+w/yt2lun3xCprSX/bqTZGCkS76a39wWT/6RURDyb
kupekU3m98Uzh6pQ5wHICxll7uHNr75f8sfwmEXTN/jjmOn5SqXl1/8P99YUsyKRS7sAAAAldEVY
dGRhdGU6Y3JlYXRlADIwMjItMDQtMDVUMTk6MzY6MzgrMDA6MDCC+8vaAAAAJXRFWHRkYXRlOm1v
ZGlmeQAyMDIyLTA0LTA1VDE5OjM2OjM4KzAwOjAw86ZzZgAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default OsirisIcon;
