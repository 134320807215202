import React from 'react';

function KoralevIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="32px"
      height="32px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      {' '}
      <image
        id="image0"
        width="64"
        height="64"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gQFEyQm8xJ9vwAAE4ZJREFUeNqtm1uMJNdZx3/f
qarununu6Z777Hr26iA7hsTeOLHjXJ1EAvKCggQP5CXiGZ5BkeAVJF4QPIBAvCAhBFJAASeQEAwi
ieMkZHNx7DjxPV571/bu7M6lp69V5+Ph1OVUdfXsJvhIvbNdfW7f9/2/6zkllz9rFL9l34Q7at2m
5bGnOvzeY1uc2bWgAqKl+UpTSvZM0yXcA0UQVVQqC6s3lwiootXtqVs271adR8skIqCqCEJ4Z2TO
N02J/+6rLb7wbId+P91WSnwcx2i2cC1T0/568hpUhuqiTnmHeYaVu6s35/+DAc1Aubof8vkfdnjy
yjKntixqBcQR32g0F225nkL/q86Dso4BtUy5LQPcv5KO/rkZEAj8w3dW+NLzXbY3MuIVay3T6ZTd
3V2CIECtzu00I0hVc5U4iQl5/0Uc1IKwWqaqOmZka3rM+7kY0GtZ/vbJFf7zxTaNJTAiKGCtZTKZ
cOrUaYIgdGsaybU8W1WcEiLZpiTduDg74J5JTrSkBMgcwfmE7jcVZ4KqhKb7c2uadIyb5GdmwFKk
fOOlJR57tsveLGJtxWJVsDYhjmO63S4rKyvYJMFaX+PqxExuEBfItkCCbz+0MKE+RzTrpyyEfsla
opifhXgROBgZ/uKrfa4cN+h3FatuxSRJMMZw6tRpksQZwEwatZqbeYPMY4iUNEX83iIFdamFr3VS
AqKesqRuRzOJk6JIinXKDDjBXgnQCpQ/+tI6rw6bLC27AQLMZjMA7rrrLlRtzmiZk25GAGB96OeI
LG0ld2eZDmfEl/qqt56gWPfYZMT7Vj9FjmruhusRUGGvEYgC5e++vcJ3ri2hoRAFzpLOYkf82to6
zWYLm9jFLCy5xXr3lEm/yhA3du7hYmb7aiCa/i5URWJKA2Se+EwAz1xr8jff7CNNQ6vhJktsQpIk
tNsd1tbWiFNmzG+o4o58s55LzpOpVhZPH2bOy7f49VGc20RmeAviqWogBuVE6AeiHIwMf/iFDaKO
EEUZYcpkMqHdbrO1temI1xOIz/ROQUQdXR7xkgE084yi89CvEi94BFaJt7lXUc+65FOmyDC51KsR
KNAMlcOR4c/+e40bcUQYCiLOfY3HY5aXl1ldXUNEUKsL9L5gt/N088RnKpCZNi2F0lXivTlZRLx6
6KkhPlNBlcVeIDLK0djwH892+NLzHVa6msUSzGYzgiCg1+uztLREEicLZklXy41yYZ0L7+D5a98r
ZDRV43jfqNUitzxnzt4FKF8YBxiB711p8udfW2Vr0zrpiKKqebDT7XZur/cZx5WSthkEi/WgX+8K
cul7zHEeIjNoPsNS91KCvvqBYCH9dGNzDMiSnG+9tMRfPrFK1BaMKTg/HA7Z2Nik02m7MPc2eq8W
EoVxIkys8dCrCIH7LtAQpRk4FclpktQ2qJtjFBust1H1ZCwKoYHQCBF3RjxSSYYUWI6U599s8E9P
dXnhoMnOhkXT6MkZvQ69Xg9jAmySoLl+10RzCjbdfBBYlkObR2LqbwSnizbVSfF+z4ifJBAYS5DT
XIkwUkJDcfPUZ9XFCKvKZDIuMyAQGE2Ff3mqw1de6LC9WU5yVJWdnW3C0CO+pGtl6dsELNAIE3Y6
IzaWpticKZJvTFW4OWrw1rBFbIXIWG+jECtIYHlH/5hGYMvhBGDERaSHk4jDccRoFhKkWCkZPW+3
Vi2DwaDMgE7T8ldf7fPF57ql/N5ay2g04uzZcwRB4JghnuFaoPexCgmWi71jfvn8m9yzekRsxTEh
la6mjP/W1Q0+/+I2gzgkMi5ozYhHLBf6x/zWfa/SiWI0HVsITpkkIT94q8+/Pr+FVWfDTB3xXgaq
vg3oNi3/9nSbLz/fYUTAaiNLclx6u7GxwdLSEmpt4V0WBDuZ0RvGQhRZLm0f8Oi5fbZXYmxSGMNs
HhPA9dGIWBJGNqKX/p4oTC2sLU/5+Nk3eeT8gOVw3jGEBvYGIT/asxwmwlIlVfL3V+Qn6Vg3gfLS
jYi/v9zj1eMGaz1HvKolSRIajSYbG5sO9upHVwt8S6q3iShnOiMePH3I3VuxizvrhjSg3bTMxDLL
Az8lsc5D7HZHfPz8LU6t4NmADP/u62uHIa8cLDGOA9qhVqBfE4n6DDACf/KVdV4eNOh3NDV6mpa1
YHd3F2uTIsNbkORkVh9RJrFhvTXjw6dvcXFrDBHorIYBWWJU2V2iEAPd1pT7NoZc2FSC2PWtMu/W
RLj8epMf32zTTG3CvMXP3KefakO4HFn+9PE1fnKrSdAQjHGDpmmGt729TRAYkjihzlVXoS+iWIX9
RDjdnHGxd4zMpry5Bzapj7vCyLI/DIjU0EzxeZwIY5T3rA752NmbLEfAtMLAFA3X9pt87coqe8OI
pvGDrLRDHfHp/8Iv/6jNY8920ZZhKUqTnCQGoNtdYWVlhdlsOo/xCvHZY5XUwotTrevDBk/8dIVp
YrDVwDN1XauNGc/tLSOJY4BVmKqw3JzywNYR79oeInVJpoHZFF643uLZvS6zJKAd6Nwa9YJz2w//
4Iub9NchDAtYz2YzOp0OW1ubjviTgp1sHU/6k0Q41ZoSmYQf3lhhkKxwNBOSYmTuQRA4txxzY9gi
TgJCUSZWiMRyT2/E/VtHdFsgCXO6TwBX9iO+/9YSR5MIk8Lfh/5C4lNYhK0VIQgcPkSE4XBIu912
Rs/ak4mvSXISFcaqvKs/4GNn32CtNWNmDTNrFphMpd+IufzGOi8fLjGMnWNabsRc2jzinRsjTADU
pRsWnttb4tvXesTWEJk7IT4LsFzfsNt2Rk8QJpMJzWaTfr9PoxERz2JObBnx6WIWIVYIg5gP7N7k
oxcP6DWySFIWThKGysF0Cf2pc4OBKOc6I95/1z6b3cQRX5W+wMHQcPmtZZ6+tUxXhTBQbrvhSguz
SC+xFmst/f4qnU4nJ/72SU7x/5kVRtZy99oxHzlzkwsbllBwSn1S0aEBK0sJM5QjK2w2Y+7bOOSB
nWOizPj5LXWnz+01uXy9za1ZSC/SFP6LpF+OBLN6YZjllKPRiO3tbbrdbp7e3o747KjLkSccx8J+
EPNrF9/g7EpCmDiYYk8AAECAC7kVbs3gwa1jPrF7xHorHavz/YdjePJah5dvdNgKldDcjvgiIyrq
pmkkOBqN6Pf7dDpdRCCxdcFOWe/9yVQhttAMY36pP+TDuwM6Deuga709LGq2KBsshwkPbA54cGfo
stCq7hvX/8pBg+9db3NjHLEeKEa4jdHzfveni+OYMAxZW1snCkNX1Kw/hskJSc1I3iVRIbGwvjTh
k7u3uLAaO8OaIe6kjylQlKBcWBnx0M6AnZU4Z05V+qMx/PCNZV7YXya2huDE4r6HDP9RFgeMx2Mu
XLhIGIYktprh1ef3flkrS3nFWHa7Iz5x7jpRy/qTnNxy+yhglAe39rl/ZwABLhSsNgOvDeCb19rc
GLToGHe4oSdB36tJekUp5wZ3d8/QaDQch7LiwUl67xHvUCUkCu1mzH3rQ+7ZiJFZmsVxsupnvxvj
5r6wNOWDOyPO92b1up+qxIs3uzyz12ESB3RNWhdaqPfzxLt9u79hr9cjns1KB5W1SY7nYv1nGUKX
Aktk4Cd7HQbjItMLpI4J5fmXI7hy0OS+3ph3b42JmsCkngGjCbxws83V4xaq5dpBbUvjGLSK6pQB
iyO9ivQr0M9mjdMguxkoVw9b/OMzmxzNTI6mUGyOqlzkXgQpQDuyvHrU4p1rA9Y7szoeuUcCBxO4
MQqYxCY95tIFcb7MnT57mHAaZwzhHRGv9cQrLmkJgoS11pQoSBhMjUulU5wlmEr+XoaRAM0Arg0b
3Jqs8MiZAe/oT3Jr7zexsNaGu1cnLDdijqfhnGOq6r3PGKGgITAB3ZXuoqpwtbihFRy73waxkKDc
2z/iY+euc6qdMJxJqVdmcE+yhb2mZWSFx1/r8uTVNvdvH7PaTqCaPltoNODejQG/0B+yN2wyTAzt
4GRfm1chSxCAKIoI7+QYq3TG4En/KBbWlsY8unuTT959g81lWHhEwGImtJpwMAn499e7/M/rXT5y
5pAPdo5zo1fl6Pn+mIe2j3h2r8PNYZN2VknN5OxJPxtYrSJlpfjwxCTnBOgnFoxY7l0/5MO7+2x3
XWkruI1NqpVQAxqhJRJ4eq/N5WvLPLB1TLsBc67UwnoLHrlrwONXj7k2bJAAxj/28YjXInDJmVgc
xUF6RUwouYxKhucTD26NiRU2WjMubR7zjvUJJnTSEvszfrw0QYBb4wbff7PLj6+33NrVICcBE8Ld
a2PevT5kNYqZ2MLmlCVfD8GMeKHufkCmr7VHUqn0VRgkcHH1mIdPDehnMbsf+9/pJ8/0XDhoVHhm
r8PXr3YZzmoYkG5rbdny0NaAsytDjhLSixrzMKei9wXa3V/veNxBKCO+Xu8lzfogloT7d/Z5z6kj
5+ZukznfURNoB8orgyb/dW2Z149qxQQJRAbes3PEPVuHHFsltoXBrRIv3mFMfhSX2gqT16fTM+Nc
GWqIF5RpIlixvG/rkIdPHdNtFch5O1pglCbClVtdvn5llYMh8yeY6d7O9JX3bk04256igLUZmWXi
M6kX0JeiJO8jQFPLp57B8KmzKkysEAYJHzp9wC+uj/Kq7tvZOoFyPG7yzat9fnor8vIFb18KQQPu
3xzy3q1DVCzjmqqTf/xe10x2qFZSlVKsUhw0ztRJf7s94tLOgJ1OPF+t+blbwegloySJ4dmbyzx9
vct0yrwapDbk/OqYh0/v04oSpupyk9SCp8awFAZ5kaAj0mR+08FeSw6hpDUqHM+EbmPGR3dvcHFj
VButvR1sCMRleG8dN7n8Rpsr+2CjGhQksLYE79yKObcyphlYZlZy4jPgZ/IsbhoW0aKB7BjJT/Eo
PckyvhjlQn/IB04P2G57Vu92Of/tPpUmQMMoNjH8aK/DN17roYnktYOSkAI4tzbhfaf26TZipl5x
xZd/8f9yfcD4vr0cdBQPFGf5242YS1uH3Ls5pRmmXczb9KlstGGUhoHXD1s88doatw5NXgqvjtvs
xDy8e8ROe8JEMwaUE65aLRUwOnenJttEAX2LMrbC2c6ES1u36HRmedCo8jZ8cEUV1bTCjDuuC4DR
LOCl/SbfudZknMj8WAutAC5uJNyzPsSKkpA7tYWJkksBlHBxt/SMUBS1Dv7neyMSG/Lcm0sYJD0H
vAOlPvFHwYSW1w+bGGuIPA8UiBKK8NZxk39+foNO6wbdhs4VmYMAjqYBvWZCv5Fg4+BE4hGB2YzJ
K694HrYuZ/ZaKDCNDd99s89T1+1tM7x82pIlrm+hsbxws40khpbxMhOBRqBM4pD/vdpno5XQjiqJ
jWYnUsK1QZNlAwOqCWyZeI1n6GhE79L7kfd/4BGV9FJz/rJHRpwUi80SoRnFBEEyx7AFD7yvWn1Q
ai7AMkzigAAhEC9gVSVRIbZKpzXzLj2UxwPENiBJDLFNj9HniE/VdjAganW5768/R5id6pTyAMpo
Qd1B5zgOSGZB6YqKeBt1z9zlw8w+iOYKNccZzS4wp6F3JO5wo2qJDK7sfTSOsF59HykXmAKBUHSe
+DwLMiT7N4kabS589o8JVnqE6l1EFI8Q5zULxogogVcy1hpd0RS3pcfZOwHVOoPk/6CphzM1RZds
ijDbU5aw5jfGC4b5XnKuCGUMyeEBYWOJrU99mpWHPkQyOCJ0t6/FXUnLr3CWoZNJKjBKkEvfjxs8
D5veGsVQuu3tVRyKSExPOsnxeou70xvlJeuy1/Jl4SXt+Baf2QymMb1HPsJdv/27JIMjxzRJoSha
WP18fJ4XpFUWLZJiyd7gyDchKfE2TUK8q+5VyeTE2wrx6kFLvFq/U6zsd6247BLxeU7jjRfD7I1r
rLzrfZz5nd/HTovDRlMqk2awzHjgQbJ07y6HdbGFIpQ+oQieW7ZMsjUvPhRGJd1SRa7eazVzWMnH
FP3FGGbXrtK9593s/OZnaOzchaa3WyGtCPk8KG2kzsfnGnKHftDfqFS++3Wq/PxA5gb653nldCBF
Si0HnSzs8TFRd5WtT32a3qO/QjIeQ9SAMIIwwuSz+9mTFFLNF8utegqzkglQLJ7002zMh38JAKkK
+eqTbz5/ySFLaMRzy5K/QDXH4CwDzNHjmBO/fpVTv/EZ1n7110mGQzQIUGPyTyhp0cMvG8z5dB9W
3uJuaAFlyT2KFgYx65sZwmwuz8eKZyj9Mb4oc8aLpz5ezJ/bx/Smi6oymU64cOkSp+wx4eOfI5lO
5hgX1qyV3w2mBmBl96YFYPx+C7KPzGfnLyzMzzo3YGEik6mNVhYooUKRfo/DH/+A6eUnvIWLQWEl
rigTkKFDbXbzFrR480Y9aRYhQnrI6pcfPEOTS60QGWALv+5tMNMksVmwNucvvXn8F6pch2arxcsv
vkSSeIcLlUwxzN4D8PXXl7r69kDJX0ouXn4oLFSJgfkfLyJPPVlxYTQTsUlL8G4vkup9LofcQaVM
9dCXzZO9hFmolesShAHG3fefqw1JcVfYq5TnG3FDpOBCGZLqw1PyDRYqYQpOe+jwi69VzyB+dOjz
0gvM/EpPrgL+prLdpC40DAIvMdAc6dkwU/jisvsoguMiPpD53aY/eVZcpPxb+rsLnAoplSFPsZZ6
u6uE1eUr0J5Q8gBWsmG+WEqt5NlE+D8MjduqxDHuewAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0w
NC0wNVQxOTozNjozNyswMDowMHSzuzMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDQtMDVUMTk6
MzY6MzcrMDA6MDAF7gOPAAAAAElFTkSuQmCC"
      />
    </svg>
  );
}

export default KoralevIcon;
