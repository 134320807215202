import * as React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import AppContainer from './AppContainer';
import WordGame from './routes/typespeed/WordGame';
import CycleDataDisplay from './routes/cycledata/CycleDataDisplay';
import ProgressBarGame from './routes/progressbar/ProgressBarGame';
import Eudaimonia from './routes/eudaimonia/Eudaimonia';

class App extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppContainer />}>
            <Route index element={<Home />} />
            <Route path="typespeed" element={<WordGame />} />
            <Route path="cycledata" element={<CycleDataDisplay />} />
            <Route path="progress" element={<ProgressBarGame />} />
            <Route path="eudaimonia" element={<Eudaimonia />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
