import {
  Card,
  CardActionArea,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ProgressBarWithOverlay from '../../shared/ProgressBarWithOverlay';

class GeneratorTile extends React.PureComponent {
  getProgress = () => {
    const { generatorData } = this.props;

    return (generatorData.progress / generatorData.maxProgress) * 100;
  };

  getPurchaseCard = (generatorData, purchaseUpgrade) => (
    <Tooltip title={generatorData.purchaseDescription}>
      <Card sx={{ height: 40 }}>
        <CardActionArea sx={{ height: '100%' }} onClick={purchaseUpgrade}>
          <Typography align="center">{`Purchase generator: ${generatorData.upgradeCost} ${generatorData.upgradeItemType}`}</Typography>
        </CardActionArea>
      </Card>
    </Tooltip>
  );

  getGeneratorCard = (generatorData, purchaseUpgrade, collectGenerator) => (
    <Stack
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
      justifyContent="space-between"
    >
      <Tooltip title={generatorData.description}>
        <Card sx={{ width: '75%' }}>
          <CardActionArea onClick={collectGenerator}>
            <Typography align="center">{`${generatorData.name} | x(${Math.floor(
              generatorData.valueMultiplier
            )})`}</Typography>
            <ProgressBarWithOverlay value={this.getProgress()}>
              {`${generatorData.collectionCount}/${generatorData.collectionMax}`}
            </ProgressBarWithOverlay>
          </CardActionArea>
        </Card>
      </Tooltip>
      <Card sx={{ width: '25%' }}>
        <CardActionArea sx={{ height: '100%' }} onClick={purchaseUpgrade}>
          <Typography align="center">{`Upgrade: ${generatorData.upgradeCost}`}</Typography>
        </CardActionArea>
      </Card>
    </Stack>
  );

  render() {
    const { generatorData, purchaseUpgrade, collectGenerator } = this.props;
    return generatorData.level === 0
      ? this.getPurchaseCard(generatorData, purchaseUpgrade)
      : this.getGeneratorCard(generatorData, purchaseUpgrade, collectGenerator);
  }
}

GeneratorTile.propTypes = {
  generatorData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    purchaseDescription: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    maxProgress: PropTypes.number.isRequired,
    collectionCount: PropTypes.number.isRequired,
    collectionMax: PropTypes.number.isRequired,
    valueMultiplier: PropTypes.number.isRequired,
    upgradeCost: PropTypes.number.isRequired,
    upgradeItemType: PropTypes.string.isRequired,
  }).isRequired,
  purchaseUpgrade: PropTypes.func.isRequired,
  collectGenerator: PropTypes.func.isRequired,
};

export default GeneratorTile;
