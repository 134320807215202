import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import GeneratorTile from './GeneratorTile';

class Generators extends React.PureComponent {
  render() {
    const { generators, purchaseUpgrade, collectGenerator } = this.props;
    return (
      <Stack spacing={2}>
        {generators.map((gen) => (
          <GeneratorTile
            key={gen.id}
            generatorData={gen}
            purchaseUpgrade={purchaseUpgrade(gen.id)}
            collectGenerator={collectGenerator(gen.id)}
          />
        ))}
      </Stack>
    );
  }
}

Generators.propTypes = {
  generators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      purchaseDescription: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      progress: PropTypes.number.isRequired,
      maxProgress: PropTypes.number.isRequired,
      collectionCount: PropTypes.number.isRequired,
      collectionMax: PropTypes.number.isRequired,
      valueMultiplier: PropTypes.number.isRequired,
      upgradeCost: PropTypes.number.isRequired,
      upgradeItemType: PropTypes.string.isRequired,
    })
  ).isRequired,
  purchaseUpgrade: PropTypes.func.isRequired,
  collectGenerator: PropTypes.func.isRequired,
};

export default Generators;
