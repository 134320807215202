import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

class Inventory extends React.PureComponent {
  render() {
    const { items } = this.props;

    return (
      <Stack spacing={2}>
        {items.map((item) => (
          <Tooltip key={item.id} title={item.description}>
            <Typography align="center">{`${item.name} ${item.value}/${item.maxValue}`}</Typography>
          </Tooltip>
        ))}
      </Stack>
    );
  }
}

Inventory.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      maxValue: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Inventory;
